import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import ButtonBook from "components/ButtonBook";
import { toast } from "react-toastify";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import { prepareDataEmailEvents } from "helpers/PrepareData";
import PageMeta from "components/PageMeta";

function Events() {
   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({ mode: "onSubmit" });

   const onSubmit = data => {
      console.log(data);
      sendEmailEvents(data);
   };

   const sendEmailEvents = async data => {
      const response = await requestJson("/emails/emailEvents.php", RequestMethod.POST, {
         data: prepareDataEmailEvents(data),
         headers: [{ key: "Content-Type", value: "application/json" }],
      });
      if (response.isSuccess === false) {
         toast.error("Email was not sent");
      } else {
         if (response.response?.status === true) {
            reset();
            toast.success("Email was sent", { autoClose: 10000 });
         } else {
            toast.error("Email was not sent");
         }
      }
   };

   return (
      <>
         <PageMeta
            title={"Active Games London ▷ Perfect for Team Building and Parties"}
            content={
               "Looking for a unique and exciting corporate party experience in London? Consider hosting your event at Energy active games! Our games are perfect for team building."
            }
         />
         <Header />
         <div className="event_area_design bg-events">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="event_title" style={{ paddingTop: 100, paddingBottom: 50 }}>
                        <img style={{ width: "100%" }} src={require("assets/images/events/corporate_parties_title_text-min-test.webp")} alt="" />

                        <p className="mb-3 mt-1 pt-0">
                           Energize is the ultimate place to have your corporate party or team-building activity. We offer a unique experience that
                           tests the skills of players in both a physical and mental way while also offering them a great time and loads of fun.
                        </p>
                        <p className="mb-3 mt-1 pt-0">
                           By offering both cooperative and competitive styles of play, your group will be able to work together but also go
                           head-to-head.
                        </p>
                        <p className="mb-3 mt-1 pt-0">
                           When organizing for a larger group you can mix and match the players on your teams, creating a great team-building
                           experience for everyone involved.
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="FadeAway">
               <div className="p_line_event_green p_line_event_green1" />
               <div className="p_line_event_green p_line_event_green2" />
               <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
                  <div className="d-md-flex">
                     <div className="col-md-5 event_icon_area">
                        <h2>Team results</h2>
                        <p>
                           When you opt for Energize as your team-building activity, your colleagues will engage in a truly unique experience. Our
                           games are designed to foster teamwork, build trust, and encourage active participation towards the collective triumph of
                           the team.
                        </p>
                        <p>
                           By participating in our games, players enhance a range of valuable skills, including:
                        </p>
                     </div>
                     <div className="col-md-7">
                        <div className="row events-icon-row1">
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p>Analytical Thinking</p>
                                    <img src={require("assets/images/events/strategizing.webp")} alt="Strategizing_icon" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p>Communication</p>
                                    <img src={require("assets/images/events/communicating.webp")} alt="Communicating_icon" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p>Leadership</p>
                                    <img src={require("assets/images/events/leadership.webp")} alt="Leadership_icon" />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row icons_area_align">
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p className="p-critical">Brainstorming</p>
                                    <img src={require("assets/images/events/problem_solving.webp")} alt="Problem_Solving_icon" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p className="p-critical">Logical Reasoning</p>
                                    <img src={require("assets/images/events/critical_thinking.webp")} alt="Critical_Thinking_icon" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4 col-sm-4">
                              <div className="single_item_events">
                                 <div className="s_img_events">
                                    <p>Planning</p>
                                    <img src={require("assets/images/events/decesive.webp")} alt="Decesive_Decision-Making_icon" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className=" room_page_hero_area section_padding bg-events2">
            <div className="p_line_event_purp p_line_event_purp1" />
            <div className="p_line_event_purp p_line_event_purp2" />
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="event_title">
                        <h2>Groups & Birthday packages</h2>
                        <p>
                           We offer different types of packages for larger groups. Please fill in the form below and we will get back to you as soon
                           as possible.
                        </p>
                     </div>
                  </div>
               </div>

               <div className="col-md-12">
                  <div className="btn_booking btn-events btn_events">
                     <ButtonBook doesNavigate={true} navigateTo={"/booking"} title={"BOOK YOUR EVENT"} />
                  </div>
               </div>
            </div>
         </div>

         <div className="room_page_hero_area section_padding" style={{ background: "#000" }}>
            <div className="p_line_event_green p_line_event_green3" />
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="fra_mid">
                        <div className="event_subtitle">
                           <h3>Booking inquiry form</h3>
                        </div>
                     </div>
                  </div>
               </div>

               <form className="width100">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">First Name</label>
                           <input
                              type="text"
                              {...register("firstName", { required: "Please fill in this field correctly!" })}
                              className={`${errors.firstName && "is-invalid"}`}
                              placeholder="First Name"
                           />
                           <p className="error-message"> {errors.firstName && errors.firstName.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Last Name</label>
                           <input
                              type="text"
                              {...register("lastName", { required: "Please fill in this field correctly!" })}
                              className={`${errors.lastName && "is-invalid"}`}
                              placeholder="Last Name"
                           />
                           <p className="error-message"> {errors.lastName && errors.lastName.message}</p>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Email</label>
                           <input
                              type="email"
                              {...register("email", {
                                 required: "Please fill in this field correctly!",
                                 pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Invalid email address",
                                 },
                              })}
                              className={`${errors.email && "is-invalid"}`}
                              placeholder="Email Address"
                           />
                           <p className="error-message"> {errors.email && errors.email.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Phone Number</label>
                           <input
                              type="text"
                              {...register("phoneNumber", {
                                 required: "Please fill in this field correctly!",
                                 minLength: {
                                    value: 6,
                                    message: "Min length is 6",
                                 },
                                 pattern: { value: /^[0-9+-]+$/, message: "Phone Number is incorrect" },
                              })}
                              className={`${errors.phoneNumber && "is-invalid"}`}
                              placeholder="Phone number"
                           />
                           <p className="error-message"> {errors.phoneNumber && errors.phoneNumber.message}</p>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Number of possible attendees</label>
                           <input
                              type="text"
                              {...register("attendees", {
                                 required: "Please fill in this field correctly!",
                                 minLength: {
                                    value: 1,
                                    message: "Minimum ateendees is 1",
                                 },
                                 pattern: { value: /^[0-9]+$/, message: "Attendees number is incorrect" },
                              })}
                              className={`${errors.attendees && "is-invalid"}`}
                           />
                           <p className="error-message"> {errors.attendees && errors.attendees.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Type of event</label>
                           <select {...register("eventType")}>
                              <option value="Corporate Event">Corporate Event</option>
                              <option value="Birthday Party">Birthday Party</option>
                              <option value="Hen Party">Hen Party</option>
                              <option value="Stag Party">Stag Party</option>
                              <option value="Group Event">Group Event</option>
                           </select>
                        </div>
                     </div>

                     <div className="col-md-12 single_fra_input withBorder">
                        <label htmlFor="">Additional information</label>
                        <textarea placeholder="Message" {...register("additionalInfo")} defaultValue={""} />
                     </div>

                     <div className="col-md-12">
                        {/* <div className="single_fra_input2">
                           <input onClick={handleSubmit(onSubmit)} type="submit" />
                        </div> */}
                        <div className="single_fra_input2 mt-4" onClick={handleSubmit(onSubmit)}>
                           <input type="submit" style={{ display: "none" }} />
                           <ButtonBook title={"SUBMIT"} customStyle={"submit-event"} />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className="divider_img" />

         <Footer />
      </>
   );
}

export default Events;
