import React from "react";
import { AccountSummaryRankType, AccountSummaryCardTheme } from "components/CheckScores/AccountSummaryCooperative";
import ButtonBook from "components/ButtonBook";

function AccountSummaryCoopCard({ number, type, theme, energizeToken, prizeName, prizeTokensToGo, currentCardRank }) {
   return (
      <>
         {type === AccountSummaryRankType.Previous && <PreviousCard number={number} prizeName={prizeName} currentCardRank={currentCardRank} />}
         {type === AccountSummaryRankType.Current && (
            <CurrentCard number={number} theme={theme} energizeToken={energizeToken} prizeName={prizeName} />
         )}
         {type === AccountSummaryRankType.Next && (
            <NextCard number={number} theme={theme} energizeToken={energizeToken} prizeName={prizeName} prizeTokensToGo={prizeTokensToGo} />
         )}
      </>
   );
}

function PreviousCard({ number, prizeName, currentCardRank }) {
   return (
      <>
         {currentCardRank - number === 1 && (
            <div className="a_single_a" style={{ backgroundImage: AccountSummaryCardTheme.Grey.backgroundImageCard }}>
               <h3>PREVIOUS RANK:</h3>
               <div className="a_single_top">
                  <div className="ac_con" style={{ backgroundImage: AccountSummaryCardTheme.Grey.backgroundImageNumber }}>
                     {number}
                  </div>
               </div>
               <div className="p_color">
                  <p style={{ color: "#FFF" }}>YOU'VE UNLOCKED A PRIZE:</p>
                  <p style={{ color: `${AccountSummaryCardTheme.Grey.rewardTextColor}` }}>{prizeName}</p>
               </div>
            </div>
         )}
      </>
   );
}

function CurrentCard({ number, theme, energizeToken, prizeName }) {
   return (
      <div className="a_single_a a_single_a_color" style={{ backgroundImage: theme.backgroundImageCard }}>
         <h3>CURRENT RANK:</h3>
         <div className="a_single_top">
            <div className="ac_con" style={{ backgroundImage: theme.backgroundImageNumber }}>
               {number}
            </div>
            <div className="a_right">
               <div className="ar_icon">
                  <img src={require("assets/images/games/logo1v2.png")} alt="logo" />
               </div>
               <div className="ar_text">
                  <p style={{ color: "#00E646" }}>
                     Energize <br /> Tokens
                  </p>
                  <h2>{energizeToken}</h2>
               </div>
            </div>
         </div>
         <div className="p_color">
            <p style={{ color: "#FFF" }}>YOU'VE UNLOCKED A PRIZE:</p>
            <p style={{ color: `${AccountSummaryCardTheme.Violet.rewardTextColor}` }}>{prizeName}</p>
         </div>
         <div className="button-achivement">
            <ButtonBook title={"BOOK YOUR NEXT VISIT AND CLAIM"} customStyle={"earn-more-energize"} doesNavigate={true} navigateTo={"/booking"} />
         </div>
      </div>
   );
}

function NextCard({ number, theme, prizeName, energizeToken, prizeTokensToGo }) {
   return (
      <div className="a_single_a a_single_a_color_next" style={{ backgroundImage: theme.backgroundImageCard }}>
         <h3>NEXT RANK:</h3>
         <div className="a_single_top">
            <div className="ac_con" style={{ backgroundImage: theme.backgroundImageNumber }}>
               {number}
            </div>
            <div className="a_right" style={{ justifyContent: "flex-end" }}>
               <div className="ar_text">
                  <h2>
                     <img src={theme.lockIcon} alt="lock_icon" />
                     {prizeTokensToGo - energizeToken}
                  </h2>
                  <p style={{ color: "#FFF" }}>
                     Energize <br /> Tokens
                     <br />
                     To Go
                  </p>
               </div>
            </div>
         </div>
         <div className="p_color p_color_next">
            <p style={{ color: "#FFF" }}>NEXT PRIZE:</p>
            <p style={{ color: `${theme.rewardTextColor}` }}>{prizeName}</p>
         </div>
         <div className="button-achivement">
            <ButtonBook title={"EARN MORE ENERGIZE TOKENS"} customStyle={"earn-more-energize"} doesNavigate={true} navigateTo={"/booking"} />
         </div>
      </div>
   );
}

export default AccountSummaryCoopCard;
