import React, { useState } from "react";
import ContentCheckScore from "components/CheckScores/ContentCheckScore";
import SideBar from "components/CheckScores/SideBar";

function ContainerCheckScore({ isCooperative, nicknameState, cardUidState }) {
   const [subTabsActive, setSubTabsActive] = useState("RANKINGS");
   const [selectedRoom, setSelectedRoom] = useState("B-ball");

   const [roomId, setRoomId] = useState(1);
   const [gameId, setGameId] = useState(1);

   return (
      <div className="find_width_tab">
         <div className="tab-content">
            <div role="tabpanel" className="tab-pane active show" id="s1">
               <div className="all_tabs_default">
                  <div className="menu_with_content">
                     <SideBar
                        subTabsActive={subTabsActive}
                        setSubTabsActive={setSubTabsActive}
                        setSelectedRoom={setSelectedRoom}
                        selectedRoom={selectedRoom}
                        isCooperative={isCooperative}
                        setRoomId={setRoomId}
                        nicknameState={nicknameState}
                     />
                     <div className="all_content">
                        <div className="dash_content_div">
                           <div className="tab-content">
                              <ContentCheckScore
                                 subTabsActive={subTabsActive}
                                 selectedRoom={selectedRoom}
                                 isCooperative={isCooperative}
                                 nicknameState={nicknameState}
                                 roomId={roomId}
                                 gameId={gameId}
                                 setGameId={setGameId}
                                 cardUidState={cardUidState}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ContainerCheckScore;
