import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import ButtonBook from "components/ButtonBook";
import PageMeta from "components/PageMeta";

function GiftVouchers() {
   return (
      <>
         <PageMeta
            title={"Give the Gift of Adventure with Active Games Voucher"}
            content={
               "Looking for the perfect gift or experience for the gamer in your life? Check out Electric active games vouchers! Our vouchers can be used towards any of our games."
            }
         />
         <Header />
         <div className=" section_padding bg-giftVouchers">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="gift_title">
                        <img className="ml-1" src={require("assets/images/giftVouchers/gift_vouchers_title_text-min-test.webp")} alt="" />
                        <div className="col-md-9">
                           <h2>Find the ideal present for your loved ones, whether it's family, friends, or colleagues, with Energize!</h2>
                        </div>
                        <div className="col-md-9">
                           <p className="mb-3">
                              Are you searching for a unique gift option? Look no further than Energize's gift vouchers, which can be tailored to
                              accommodate any number of players and scheduled at any desired time.
                           </p>
                           <p>
                              These vouchers are conveniently available for purchase both online (with an electronic voucher delivery) and at our
                              venue.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-md-12">
                  <div className="btn_booking btn-gifts">
                     <ButtonBook
                        doesNavigate={true}
                        customStyle={"gift-voucher-btn"}
                        navigateTo={"/gift-voucher-purchase"}
                        title={"PURCHASE GIFT VOUCHER"}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default GiftVouchers;
