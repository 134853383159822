import { useState } from "react";

export default function useToken() {
   const [token, setToken] = useState(sessionStorage.getItem("token"));

   const [nickname, setNickname] = useState(sessionStorage.getItem("nickname"));

   // const [location, setLocation] = useState(sessionStorage.getItem("location"));
   const [location, setLocation] = useState("LONDON");

   const [cardUid, setCardUid] = useState(sessionStorage.getItem("cardUid"));

   const saveToken = userToken => {
      sessionStorage.setItem("token", userToken);
      setToken(userToken.token);
   };

   const saveNickname = userNickname => {
      sessionStorage.setItem("nickname", userNickname);
      setNickname(userNickname.nickname);
   };

   const saveLocation = userLocation => {
      sessionStorage.setItem("location", userLocation);
      setLocation(userLocation);
   };

   const logout = () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("nickname");
      sessionStorage.removeItem("cardUid");
   };

   const saveCardUid = userCardUid => {
      sessionStorage.setItem("cardUid", userCardUid);
      setCardUid(userCardUid);
   };

   return {
      setToken: saveToken,
      setNickname: saveNickname,
      setLocation: saveLocation,
      setCardUid: saveCardUid,
      logout: logout,
      token,
      nickname,
      location,
      cardUid,
   };
}
