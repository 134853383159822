export const goToTop = () => {
   window.scrollTo({
      top: 0,
      behavior: "smooth",
   });
};

export const getCurrentUser = () => {
   if (sessionStorage.getItem("token") !== "undefined") return sessionStorage.getItem("token");
   return "";
};

export const getGameType = isCooperative => {
   if (isCooperative) return "COOPERATIVE";
   return "COMPETITIVE";
};

const parseJwt = token => {
   var base64Url = token.split(".")[1];
   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
   var jsonPayload = decodeURIComponent(
      window
         .atob(base64)
         .split("")
         .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
         })
         .join(""),
   );

   return JSON.parse(jsonPayload);
};

export const isUserAuthenticated = () => {
   const token = getCurrentUser();
   if (token && token !== undefined) {
      const jwtObj = parseJwt(token);
      const tokenExpirationDate = new Date(jwtObj.exp * 1000);
      return tokenExpirationDate > new Date();
   } else return false;
};
