import React, { useState, useEffect } from "react";
import { getAllGamesForRoom, getScores } from "api/api";
import { getGameType } from "helpers/helpers";
import { isObject } from "lodash";

function ContentGames({ selectedRoom, isCooperative, energizeToken, nicknameState, roomId, gameId, setGameId, cardUidState, accountSummaryData }) {
   const [selectedGame, setSelectedGame] = useState("");
   const [games, setGames] = useState([]);
   const [scores, setScores] = useState([]);

   const onClickGame = game => {
      setSelectedGame(game.displayname);
      setGameId(game.id);
   };

   useEffect(() => {
      const getAllGamesForRoomAsync = async () => {
         const gameType = getGameType(isCooperative);
         const games = await getAllGamesForRoom(roomId, gameType);
         setGames(games);
         setGameId(games[0].id);
         setSelectedGame(games[0].displayname);
      };
      getAllGamesForRoomAsync();
   }, [selectedRoom, isCooperative]);

   useEffect(() => {
      const getScoresAsync = async () => {
         const scores = await getScores(gameId, roomId, cardUidState);
         setScores(scores);
      };
      getScoresAsync();
   }, [gameId]);

   const getTableScores = () => {
      if (isObject(scores)) {
         return Object.keys(scores).map((key, index) => {
            return (
               <tr key={key}>
                  <td>{index + 1}</td>
                  {scores[key].yourPoints === 0 ? <td className="cff3b1">No score</td> : <td>{scores[key].yourPoints}</td>}
                  {scores[key].topPoints === 0 ? <td className="cff3b1">No score</td> : <td>{scores[key].topPoints}</td>}
               </tr>
            );
         });
      }
   };

   return (
      <div role="tabpanel" className="tab-pane active show" id="d3">
         <div className={isCooperative ? "games_content_green" : "games_content_red"}>
            <div className="top_rank_con">
               <div className="top_rank_left">
                  <h3 className={isCooperative ? "coop_name_h3" : "comp_name_h3"}>{nicknameState}</h3>
                  {isCooperative && <h5>RANK: {accountSummaryData.rank}</h5>}
               </div>
               {isCooperative ? (
                  <div className="top_rank_right">
                     <div className="logo_rank">
                        <img src={require("assets/images/games/logo2.png")} alt="logo" />
                     </div>
                     <div className="logo_g2">
                        <p>
                           {energizeToken} <br />
                           Energize Tokens
                        </p>
                     </div>
                  </div>
               ) : (
                  <div className="top_rank_right2">
                     <h3 className="total_comp_h3">Total Competitive Game Wins All Time</h3>
                     <h4>{accountSummaryData.totalWins}</h4>
                  </div>
               )}
            </div>
            <div className="bottom_tabs_content">
               <div className="tab-content">
                  <div role="tabpanel" className="tab tab-pane active show" id="g1">
                     <div className="game_title">
                        <h2>{selectedRoom}</h2>
                     </div>
                     <div className="games_page_inner">
                        <ul className="nav nav-tabs games_in_tab cursor_p" role="tablist">
                           {games.map(game => {
                              return (
                                 <li role="presentation" key={game.id}>
                                    <a
                                       onClick={() => onClickGame(game)}
                                       // className={selectedGame === game.displayname ? "active" : ""}
                                       className={
                                          selectedGame !== game.displayname && isCooperative
                                             ? "cff3b1"
                                             : selectedGame !== game.displayname && !isCooperative
                                             ? "ffbfbf"
                                             : "active"
                                       }
                                       role="tab"
                                       data-toggle="tab"
                                    >
                                       {game.displayname}
                                    </a>
                                 </li>
                              );
                           })}
                        </ul>
                        <div className="tab-content">
                           <div className="games_tab_pannel_content">
                              <table className="table mb-0">
                                 {isCooperative ? (
                                    <thead>
                                       <tr>
                                          <th scope="col">Level</th>
                                          <th scope="col">Your Score</th>
                                          <th scope="col">Top Score</th>
                                       </tr>
                                    </thead>
                                 ) : (
                                    <thead>
                                       <tr>
                                          <th scope="col" className="text-center">
                                             Game wins
                                             <br /> today
                                          </th>
                                          <th scope="col" className="text-center">
                                             All time
                                             <br /> game wins
                                          </th>
                                          <th scope="col" className="text-center">
                                             Most wins
                                             <br /> in one day
                                          </th>
                                       </tr>
                                    </thead>
                                 )}
                                 <tbody>{getTableScores()}</tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ContentGames;
