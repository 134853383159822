import React, { useState, useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import ChangePasswordSuccess from "pages/Auth/components/ChangePasswordSuccess";
import useToken from "components/useToken";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const [finalizeStep, setShowFinalize] = useState(false);
   const router = useNavigate();
   const { nickname } = useToken();

   const handleChangePassword = data => {
      setShowFinalize(true);
   };

   useEffect(() => {
      if (!nickname) router("/loginregister");
   }, [nickname]);

   return (
      <>
         <Header />
         <div className="section_padding bg-recoverPassword">
            {finalizeStep === true ? (
               <ChangePasswordSuccess />
            ) : (
               <div className="container changepassword-container">
                  <div className="row">
                     <div className="recover-password">
                        <img className="titleChange-password" src={require("assets/images/changePassword/Change_Password_text-min.webp")} alt="" />
                     </div>
                  </div>

                  <div className="col-md-12 p-0">
                     <div className="login_single single_fra_input very_long_input">
                        <input
                           type="text"
                           {...register("currentPassword", {
                              required: "Please fill in this field correctly!",
                           })}
                           className={`${errors.currentPassword ? "is-invalid" : "is-valid"}`}
                           placeholder="your current password"
                        />
                        <p className="error-message">{errors.currentPassword && errors.currentPassword.message}</p>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="text"
                              {...register("newPassword", {
                                 required: "Please fill in this field correctly!",
                              })}
                              className={`${errors.newPassword ? "is-invalid" : "is-valid"}`}
                              placeholder="new password"
                           />
                           <p className="error-message">{errors.newPassword && errors.newPassword.message}</p>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="text"
                              {...register("confirmNewPassword", {
                                 required: "Please fill in this field correctly!",
                                 validate: value => {
                                    if (watch("newPassword") !== value) {
                                       return "Your passwords do not match";
                                    }
                                 },
                              })}
                              className={`${errors.confirmNewPassword ? "is-invalid" : "is-valid"}`}
                              placeholder="confirm password"
                           />
                           <p className="error-message">{errors.confirmNewPassword && errors.confirmNewPassword.message}</p>
                        </div>
                     </div>
                  </div>

                  <div className="login_single login_btn register_btn register_btn_sub buttonAccount buttonChange">
                     <img src={require("assets/images/buttons/Submit_btn_crop-min.webp")} alt="" onClick={handleSubmit(handleChangePassword)} />
                  </div>
               </div>
            )}
         </div>

         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default ChangePassword;
