import React from "react";
import { useNavigate } from "react-router-dom";
function ComingSoon() {
   const router = useNavigate();
   return (
      <div onClick={() => router("/")} className="text-center container_coming_soon cursor_p">
         <div className="container">
            <img className="mt-4" src={require("assets/images/Logo_Popup-min.webp")} alt="" />
            <h1>Coming Soon!</h1>
         </div>
      </div>
   );
}

export default ComingSoon;
