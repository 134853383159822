import React, { createContext, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "assets/css/main.scss";
import Home from "pages/Home";
import Rooms from "pages/Rooms";
import CheckScores from "pages/CheckScores";
import Auth from "pages/Auth/Auth";
import Account from "pages/Account";
import RecoverPassword from "pages/Auth/RecoverPassword";
import RecoverPasswordNewPassword from "pages/Auth/RecoverPasswordNewPassword";
import Franchise from "pages/Franchise";
import GiftVouchers from "pages/GiftVouchers";
import FAQ from "pages/FAQ";
import Events from "pages/Events";
import Booking from "pages/Booking";
import GiftVouchersPurchase from "pages/GiftVouchersPurchase";
import Prices from "pages/Prices";
import ComingSoon from "pages/ComingSoon";
import ChangePassword from "pages/Auth/ChangePassword";
import ChangeEmail from "pages/Auth/ChangeEmail";
import NotFound404 from "pages/NotFound404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScrollContext = createContext({});
function App() {
   const scrollToAboutUsRef = useRef(null);
   const [scrollToAboutUs, setScrollToAboutUs] = useState(false);

   return (
      <>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
         <ScrollContext.Provider value={{ scrollToAboutUsRef, scrollToAboutUs, setScrollToAboutUs }}>
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/rooms" element={<Rooms />} />
               <Route path="/checkscores" element={<CheckScores />} />

               <Route path="/loginregister" element={<Auth />} />
               <Route path="/account" element={<Account />} />
               <Route path="/recover-password" element={<RecoverPassword />} />
               <Route path="/recover-password-new-password" element={<RecoverPasswordNewPassword />} />

               <Route path="/change-password" element={<ChangePassword />} />
               <Route path="/change-email" element={<ChangeEmail />} />

               <Route path="/booking" element={<Booking />} />

               <Route path="/gift-voucher" element={<GiftVouchers />} />
               <Route path="/gift-voucher-purchase" element={<GiftVouchersPurchase />} />

               <Route path="/prices" element={<Prices />} />
               <Route path="/franchise" element={<Franchise />} />
               <Route path="/faq" element={<FAQ />} />
               <Route path="/events" element={<Events />} />
               <Route path="/coming-soon" element={<ComingSoon />} />
               <Route path="*" element={<NotFound404 />} />
            </Routes>
         </ScrollContext.Provider>
      </>
   );
}
export { ScrollContext };
export default App;
