export const userSettings = {
   nickname: sessionStorage.getItem("nickname") ? sessionStorage.getItem("nickname") : "alin33",
   password: sessionStorage.getItem("password") ? sessionStorage.getItem("password") : "test11",
   cardUid: sessionStorage.getItem("cardUid") ? sessionStorage.getItem("cardUid") : "4028783892",
};

export const dataToLoginForCheckScores = {
   nickName: userSettings.nickname,
   password: userSettings.password,
};
