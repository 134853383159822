import React, { useState, useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import ChangeEmailSuccess from "pages/Auth/components/ChangeEmailSuccess";
import useToken from "components/useToken";
import { useNavigate } from "react-router-dom";

function ChangeEmail() {
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const [finalizeStep, setShowFinalize] = useState(false);
   const router = useNavigate();
   const { nickname } = useToken();

   const handleChangeEmail = data => {
      setShowFinalize(true);
   };

   useEffect(() => {
      if (!nickname) router("/loginregister");
   }, [nickname]);

   return (
      <>
         <Header />
         <div className="section_padding bg-changeEmail">
            {finalizeStep ? (
               <ChangeEmailSuccess />
            ) : (
               <div className="container changeemail-container">
                  <div className="row">
                     <div className="recover-password">
                        <img className="titleChange" src={require("assets/images/changeEmail/Change_email_Address_text-min.webp")} alt="" />
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="email"
                              {...register("newEmail", {
                                 required: "Please fill in this field correctly!",
                                 pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Invalid email address",
                                 },
                              })}
                              className={`${errors.newEmail ? "is-invalid" : "is-valid"}`}
                              placeholder="new email address"
                           />
                           <p className="error-message">{errors.newEmail && errors.newEmail.message}</p>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="email"
                              {...register("confirmNewEmail", {
                                 required: "Please fill in this field correctly!",
                                 pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Invalid email address",
                                 },
                                 validate: value => {
                                    if (watch("newEmail") !== value) {
                                       return "Your emails do not match";
                                    }
                                 },
                              })}
                              className={`${errors.confirmNewEmail ? "is-invalid" : "is-valid"}`}
                              placeholder="confirm email address"
                           />
                           <p className="error-message">{errors.confirmNewEmail && errors.confirmNewEmail.message}</p>
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="login_single login_btn register_btn register_btn_sub buttonAccount buttonChange">
                           <img src={require("assets/images/buttons/Submit_btn_crop-min.webp")} alt="" onClick={handleSubmit(handleChangeEmail)} />
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </div>

         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default ChangeEmail;
