import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import FAQComponent from "components/FAQComponent";
import faqData from "data/faq.json";
import PageMeta from "components/PageMeta";

function FAQ() {
   const [faqs, setFaqs] = useState(faqData);

   const toggleFAQ = index => {
      setFaqs(
         faqs.map((faq, i) => {
            if (i === index) {
               faq.open = !faq.open;
            } else {
               faq.open = false;
            }
            return faq;
         }),
      );
   };

   return (
      <>
         <PageMeta
            title={"Arcade style active games FAQ Page ▷ Energize"}
            content={
               "Looking for information about arcade style active games? Our FAQ page has answers to common questions about this indoor multiplayer gaming experience."
            }
         />
         <Header />
         <div className="bg-faq section_padding">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="faq_title fr_title2">
                        <div className="faq_title_text">
                           <img className="faq_web" src={require("assets/images/faq/faq_title_text_web-min-test.webp")} alt="" />
                           <img className="faq_mobile" src={require("assets/images/faq/faq_title_text_mobile-min-test.webp")} alt="" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="faqs">
                  {faqs.map((faq, i) => (
                     <FAQComponent faq={faq} index={i} key={i} toggleFAQ={toggleFAQ} />
                  ))}
               </div>
            </div>
         </div>
         <div className="divider_img" />

         <Footer />
      </>
   );
}

export default FAQ;
