import React, { useEffect, useState } from "react";
import { getAllRooms } from "api/api";
import gameIcons from "data/games.json";
import { toast } from "react-toastify";

function SideBar({ isCooperative, subTabsActive, setSubTabsActive, setSelectedRoom, setRoomId, selectedRoom, nicknameState }) {
   const [gameShow, setGameShow] = useState(false);
   const [rooms, setRooms] = useState([]);

   const getGamesClassName = () => {
      if (gameShow === true) return "card-body collapse show";
      else return "card-body collapse";
   };

   const getSubTabClassName = currentTab => {
      if (currentTab === subTabsActive) {
         if (isCooperative) return "active green";
         else return "active red red-glow";
      } else if (!isCooperative) return "no-red-glow";
   };

   const clickOnRoom = room => {
      setSelectedRoom(displayCorrectName(room.name));
      setRoomId(room.id);
   };

   const displayCorrectName = name => {
      return name.split("_")[0];
   };

   const roomsWithIcons = rooms.map(value => {
      let obj = gameIcons.find(object => object.id === value.id);
      if (obj) {
         value.iconGreen = obj.iconGreen;
         value.iconRed = obj.iconRed;
      }
      return value;
   });

   const AccountSummaryClick = () => {
      if (!nicknameState) {
         // toast.error("Please enter any nickname first, or login to your account to view any account summary");
      } else {
         setSubTabsActive("ACCOUNTSUMMARY");
      }
   };

   const GamesClick = () => {
      if (!nicknameState) {
         // toast.error("Please enter any nickname first, or login to your account");
      } else {
         setGameShow(true);
         setSubTabsActive("GAMES");
      }
   };

   useEffect(() => {
      if (subTabsActive !== "GAMES") {
         setGameShow(false);
      }
   }, [isCooperative, subTabsActive]);

   useEffect(() => {
      const getAllRoomsAsync = async () => {
         const rooms = await getAllRooms();
         setRooms(rooms);
      };
      getAllRoomsAsync();
   }, []);

   return (
      <div className="tab_menu ">
         <ul className="nav nav-tabs cursor_p" role="tablist">
            {isCooperative &&
                  <li role="presentation" onClick={() => setSubTabsActive("RANKINGS")}>
                     <a style={{ color: "white" }} className={getSubTabClassName("RANKINGS")} role="tab" data-toggle="tab">
                        Leaderboard
                     </a>
                  </li>
            }
            <li role="presentation" onClick={() => AccountSummaryClick()} className={`${!nicknameState && "disabled-sidebar"}`}>
               <a style={{ color: "white" }} className={getSubTabClassName("ACCOUNTSUMMARY")} role="tab" data-toggle="tab">
                  Account Summary
               </a>
            </li>
            <li role="presentation" className={`${!nicknameState && "disabled-sidebar"}`}>
               <a onClick={() => GamesClick()} className={getSubTabClassName("GAMES")} role="tab" data-toggle="tab">
                  <div id="accordion" className="accordion">
                     <div className="card mb-0">
                        <div
                           className={`${subTabsActive !== "GAMES" ? "card-header collapsed" : "card-header"} ${
                              !nicknameState && "disabled-sidebar"
                           }`}
                           data-toggle="collapse"
                           href="#collapseOne"
                        >
                           <div className="card-title">Games</div>
                        </div>
                     </div>
                  </div>
               </a>
               <div id="collapseOne" className={[getGamesClassName(), isCooperative ? "green" : "red"].join(" ")} data-parent="#accordion">
                  <ul className="nav nav-tabs games_tab" role="tablist">
                     {roomsWithIcons.map(room => {
                        return (
                           <li role="presentation" key={room.id}>
                              <a
                                 className={`text-uppercase ${
                                    selectedRoom !== displayCorrectName(room.name) && isCooperative
                                       ? "cff3b1"
                                       : selectedRoom !== displayCorrectName(room.name) && !isCooperative
                                       ? "ffbfbf"
                                       : ""
                                 }`}
                                 onClick={() => clickOnRoom(room)}
                              >
                                 <img src={require(`assets/images/checkScores/${isCooperative ? room.iconGreen : room.iconRed}`)} alt="" />
                                 {displayCorrectName(room.name)}
                              </a>
                           </li>
                        );
                     })}
                  </ul>
               </div>
            </li>
         </ul>
      </div>
   );
}

export default SideBar;
