import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import ButtonBook from "components/ButtonBook";
import { prepareDataEmailFranchise } from "helpers/PrepareData";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import { toast } from "react-toastify";
import PageMeta from "components/PageMeta";

function Franchise() {
   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({ mode: "onSubmit" });

   const onSubmit = data => {
      sendEmailFranchise(data);
   };

   const sendEmailFranchise = async data => {
      const response = await requestJson("/emails/emailFranchise.php", RequestMethod.POST, {
         data: prepareDataEmailFranchise(data),
         headers: [{ key: "Content-Type", value: "application/json" }],
      });

      if (response.isSuccess === false) {
         toast.error("Email was not sent");
      } else {
         if(response.response?.status === true) {
            reset();
            toast.success("Email was sent", { autoClose: 10000 });
         }else{
            toast.error("Email was not sent");
         }
      }
   };

   return (
      <>
         <PageMeta
            title={"Franchise arcade style active games ▷  Energize"}
            content={
               "Interested in opening your own interactive active games franchise? Consider Energize ! Our franchise opportunity offers a unique and exciting business model."
            }
         />
         <Header />
         <div className="bg-franchise section_padding">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="fr_title franchise_title text-center">
                        <img src={require("assets/images/franchise/franchise_title_text-min-test.webp")} alt="" />
                        <p>Interested in opening an Energize venue in your city?</p>
                        <div className="p_line p_line1" />
                        <div className="p_line p_line2" />
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <div className="single_fra">
                        <h3>How do I start?</h3>
                        <p>
                           All you need is a large enough, well situated space to fit our rooms as well as appropriate reception, lockers and toilet
                           facilities.
                        </p>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="single_fra">
                        <h3>Energize Services</h3>
                        <p>
                           We offer full, end to end Franchising services that include the building, development, transportation and installation of
                           our games, as well as specialized consultancy and guidance on running the venue, marketing and sales.
                        </p>
                        <p className="mt-2">Contact us directly for more information.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="fra_mid">
                        <div className="shapping_22 fra2">
                           <img src={require("assets/images/franchise/franchise_icons.webp")} alt="" />
                        </div>
                        <div className="contact_us_more">
                           <h3>Contact us for a more detailed discussion</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <form>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Name Surname</label>
                           <input
                              {...register("nameSurname", { required: "Please fill in this field correctly!" })}
                              className={`${errors.nameSurname && "is-invalid"}`}
                              type="text"
                              placeholder="Name Surename"
                           />
                           <p className="error-message"> {errors.nameSurname && errors.nameSurname.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Email Address</label>
                           <input
                              {...register("email", {
                                 required: "Please fill in this field correctly!",
                                 pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Invalid email address",
                                 },
                              })}
                              className={`${errors.email && "is-invalid"}`}
                              type="email"
                              placeholder="Email Address"
                           />
                           <p className="error-message"> {errors.email && errors.email.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Phone Number</label>
                           <input
                              {...register("phoneNumber", {
                                 required: "Please fill in this field correctly!",
                                 minLength: {
                                    value: 6,
                                    message: "Min length is 6",
                                 },
                                 pattern: { value: /^[0-9+-]+$/, message: "Phone Number is incorrect" },
                              })}
                              className={`${errors.phoneNumber && "is-invalid"}`}
                              type="text"
                              placeholder="Phone number"
                           />
                           <p className="error-message"> {errors.phoneNumber && errors.phoneNumber.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">City</label>
                           <input
                              {...register("city", { required: "Please fill in this field correctly!" })}
                              className={`${errors.city && "is-invalid"}`}
                              type="text"
                              placeholder="City"
                           />
                           <p className="error-message"> {errors.city && errors.city.message}</p>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="single_fra_input withBorder">
                           <label htmlFor="">Message</label>
                           <textarea {...register("additionalInfo")} placeholder="Message" defaultValue={""} />
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="single_fra_input2 mt-4" onClick={handleSubmit(onSubmit)}>
                           <input type="submit" style={{ display: "none" }} />
                           <ButtonBook title={"SEND MESSAGE"} customStyle={"submit-event"} />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default Franchise;
