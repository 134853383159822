import React, { useState } from "react";
import ModalVideo from "components/ReactVideoModal";

function RoomTemplateFirst({ game, lastGame, odd }) {
   const [isOpen, setOpen] = useState(false);

   const getClassFirst =
      game.id === 1
         ? "rooms_default_area flex-row section_padding2 bg-1-room"
         : `rooms_default_area flex-row rooms_default_area3 section_padding2 bg-${game.id}-room`;
   const getShape = () => {
      if (game.id === 1) {
         return (
            <div className={"shap_position"}>
               <img src={require("../assets/images/room/1/s_bottom.webp")} altshap_position_last="" />
            </div>
         );
      } else {
         return (
            <>
               <div className={"create_shap"}></div>
               <div className={lastGame.id === game.id ? "shap_position shap_position_last" : "shap_position"}>
                  <img src={require("../assets/images/room/1/s_bottom.webp")} alt="" />
               </div>
            </>
         );
      }
   };

   const getImage = (game, name) => {
      if (odd && name === "s1") return <img src={require("../assets/images/room/1/" + name + ".webp")} alt="" />;
      if (odd && name === "s2") return <img src={require("../assets/images/room/1/" + name + ".webp")} alt="" />;
      try {
         return <img src={require("../assets/images/room/" + game.id + "/" + name + ".webp")} alt="" />;
      } catch (err) {
         console.log(err, "err getimage");
      }
   };

   return (
      <div className={getClassFirst}>
         {/*<ModalVideo videoId={game.videoUrl} channel="youtube" isOpen={isOpen} onClose={() => setOpen(false)} />*/}
         <div className="container">
            <div className="row">
               <div className="col-lg-5 order-lg-1 cursor_p">
                  <div className="default_video">
                     <div onClick={() => setOpen(true)}>{getImage(game, "video")}</div>
                  </div>
               </div>
               <div className="col-lg-7 order-lg-1">
                  <div className="default_content_full">
                     <div className="default_top ">
                        <div className="de_left">{getImage(game, "img1")}</div>
                        <div className="text">
                           <div className="img">{getImage(game, "s1")}</div>
                           <div className="d_text_img">{getImage(game, "text")}</div>
                           <div className="de_position de_position2">{getImage(game, "s2")}</div>
                        </div>
                     </div>
                     <div className="de_content">
                        <p>{game.description}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {getShape()}
      </div>
   );
}

export default RoomTemplateFirst;
