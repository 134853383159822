import Modal from "react-modal";
function Waiver({ showModal, handleModal }) {
   const content = {
      content: {
         backgroundColor: "rgba(25,126,56,1)",
         border: "3px solid #fff",
         boxShadow: "inset 0 0 2vw #00e646, 0 0 2vw #00e646",
         color: "#fff",
         fontSize: "19px",
         fontStyle: "italic",
         paddingRight: "5vw",
         paddtingTop: "5vw",
         left: "14vw",
         right: "14vw",
         top: "40px",
         bottom: "40px",
      },
   };

   const closeModal = () => {
      document.body.style.overflow = "unset";
      handleModal();
   };

   return (
      <Modal
         isOpen={showModal}
         ariaHideApp={false}
         style={content}
         contentLabel="Example Modal"
         onAfterOpen={() => {
            document.body.style.overflow = "hidden";
         }}
      >
         <div className="col-md-12">
            <div className="modal-header">
               <h2>Waiver acceptance</h2>
            </div>
            <div className="modal-body">
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto autem beatae consequatur culpa cumque cupiditate dolorum
                  eos excepturi expedita facere illum iste iure laudantium neque nesciunt omnis placeat quas quidem reprehenderit rerum saepe vel
                  velit voluptas, voluptatum? Adipisci, doloremque dolores esse numquam odio qui sed. Alias animi aut commodi corporis, doloremque
                  dolorum earum eum incidunt minima modi, nam numquam perferendis perspiciatis quaerat quasi qui quod velit? Adipisci blanditiis
                  ducimus error est magni. Ab, alias, architecto beatae corporis cum cumque delectus earum eius eos esse harum incidunt inventore iure
                  laboriosam neque nesciunt officiis praesentium quam quis sed similique velit voluptates. Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Amet architecto autem beatae consequatur culpa cumque cupiditate dolorum eos excepturi expedita facere illum iste
                  iure laudantium neque nesciunt omnis placeat quas quidem reprehenderit rerum saepe vel velit voluptas, voluptatum? Adipisci,
                  doloremque dolores esse numquam odio qui sed. Alias animi aut commodi corporis, doloremque dolorum earum eum incidunt minima modi,
                  nam numquam perferendis perspiciatis quaerat quasi qui quod velit? Adipisci blanditiis ducimus error est magni. Ab, alias,
                  architecto beatae corporis cum cumque delectus earum eius eos esse harum incidunt inventore iure laboriosam neque nesciunt officiis
                  praesentium quam quis sed similique velit voluptates.
               </p>
               <img className="image_modal cursor_p" onClick={() => closeModal()} src={require("assets/images/i-agree-min.webp")} alt="I_agree" />
            </div>
         </div>
      </Modal>
   );
}

export default Waiver;
