import axios from "axios";
import { sha512 } from "js-sha512";
import { requestJson, RequestMethod } from "helpers/httpHelper";

const baseURL = "https://uvvo.net:8443/EnergizeGamesServer/api/v1/";
const api = axios.create({
   baseURL: baseURL,
});

export const login = async data => {
   const hashPassword = sha512(data.password);
   const response = await requestJson(`${baseURL}login`, RequestMethod.POST, {
      data: {
         nickname: data.nickName,
         password: hashPassword,
      },
      headers: [{ name: "Content-Type", value: "application/x-www-form-urlencoded" }],
   });
   return response;
};

export const finalizeRegisterApi = async data => {
   const response = await requestJson(`${baseURL}login`, RequestMethod.POST, {
      data: {
         nickname: data.nickname,
         password: data.hashPassword,
      },
      headers: [{ name: "Content-Type", value: "application/x-www-form-urlencoded" }],
   });
   return response;
};

export const registerApi = (data, callBack) => {
   var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
   var currentDate = new Date(Date.now() - tzoffset).toISOString().slice(0, -1) + "Z";
   const hashPassword = sha512(data.passwordRegister);
   api.post(
      "/users/create",
      {
         nickname: data.nickNameRegister,
         email: data.emailRegister,
         password: hashPassword,
         lastaction: currentDate,
         registertime: currentDate,
         weaveracceptance: true,
         energizeToken: 0,
         surname: "",
         name: "",
      },
      { headers: { "Content-Type": "application/json" } },
   ).then(response => {
      callBack(response);
   });
};

export const getEnergizeToken = async nickname => {
   //nickname - static for now (razvan)
   // const response = await requestJson(`${baseURL}cards?nickname=razvan`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}cards?nickname=${nickname}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data[0]?.userid.energizeToken;
};

export const getCardUid = async nickname => {
   //nickname - static for now (razvan)
   // const response = await requestJson(`${baseURL}cards?nickname=razvan1`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}cards?nickname=${nickname}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   if (response.response.data.length === 0) return "Card not found!";
   else return response.response.data[0].uid;
};

export const getAllRooms = async () => {
   const response = await requestJson(`${baseURL}rooms/1/11`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data;
};

export const getAllGamesForRoom = async (roomId, gameType) => {
   const response = await requestJson(`${baseURL}rooms/${roomId}/games?gameType=${gameType}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data;
};

export const getScores = async (gameId, roomId, cardUid) => {
   //cardUid - static for now (4028151300)
   // const response = await requestJson(`${baseURL}rooms/${roomId}/games/${gameId}/levels/scores?cardUID=4028151300`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}rooms/${roomId}/games/${gameId}/levels/scores?cardUID=${cardUid}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data[0]?.levelidScore;
};

export const getAccountSummary = async (gameType, nickname) => {
   //nickname - static for now (razvan)
   // const response = await requestJson(`${baseURL}scores/summary?gameType=${gameType}&userkey=razvan`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}scores/summary?gameType=${gameType}&userkey=${nickname}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0];
};

export const getAccountSummaryPrizes = async (gameType, nickname) => {
   //nickname - static for now (razvan)
   // const response = await requestJson(`${baseURL}scores/summary?gameType=${gameType}&userkey=razvan`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}scores/summary?gameType=${gameType}&userkey=${nickname}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0].prizes;
};

export const getAccountSummaryCurrentCardRank = async cardUid => {
   //cardUid - static for now (4028151300)
   // const response = await requestJson(`${baseURL}rooms/1/games/1/levels/scores?cardUID=4028151300`, RequestMethod.GET, {
   const response = await requestJson(`${baseURL}rooms/1/games/1/levels/scores?cardUID=${cardUid}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0]?.currentRank.userrank;
};

export const getRankingsGlobal = async () => {
   const response = await requestJson(`${baseURL}scores/ranks`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data;
};
