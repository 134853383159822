import React from "react";
import ButtonBook from "components/ButtonBook";
import termsAndConditions from "assets/Terms and Conditions Energize.pdf";
import dataProtection from "assets/DataProtectionEnergize.pdf";

function Footer() {
   return (
      <div className="footer_area_design" id="contact">
         <div className="container">
            <div className="row footer_sm">
               <div className="col-xl-12">
                  <div className="c_title">
                     <h2>Contact us</h2>
                  </div>
               </div>
               <div className="col-md-5">
                  <div className="c_details">
                     <div id="details">
                        <div className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_mail.webp")} alt="mail_icon" />
                           </div>
                           <p>
                              <a href="mailto:info@energize.co.uk">info@energize.co.uk</a>
                           </p>
                        </div>
                        <div className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_tel.webp")} alt="tel_icon" />
                           </div>
                           <p>
                              <a href="tel:07570076899">07570076899</a>
                           </p>
                        </div>
                        <div className="single_de">
                           <div className="c_img location_pin_icon">
                              <img src={require("assets/images/footer/icon_pin.webp")} />
                           </div>
                           <p>
                              <a href="https://www.google.com/maps/dir//51.5392222,-0.1036944/@51.5392222,-0.1036944,15z" target="_blank">
                                 Unit G6A, 6F Esther Anne Place, Islington Square, N1 1WL, London, UK
                              </a>
                           </p>
                        </div>
                        <div className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_calendar.webp")} alt="calendar_icon" />
                           </div>
                           <p>Monday - Thursday & Sunday</p>
                        </div>
                        <div className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_ceas.webp")} alt="clock_icon" />
                           </div>
                           <p>10:00 - 22:00</p>
                        </div>
                        <div className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_calendar.webp")} alt="calendar_icon" />
                           </div>
                           <p>Friday - Saturday</p>
                        </div>
                        <div style={{ paddingBottom: 25 }} className="single_de">
                           <div className="c_img">
                              <img src={require("assets/images/footer/icon_ceas.webp")} alt="clock_icon" />
                           </div>
                           <p>10:00 - 23:00</p>
                        </div>
                     </div>
                     <ButtonBook doesNavigate={true} navigateTo={"/booking"} title={"BOOK NOW"} id="booknow" customStyle={"book-now-home"} />
                  </div>
               </div>
               <div className="col-md-7" id="map">
                  <div className="all_map_area">
                     <div className="footer_area-map map-bg">
                        <iframe
                           className="iframe_footer"
                           src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d9926.32694777022!2d-0.103618!3d51.539231!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDMyJzIxLjIiTiAwwrAwNicxMy4zIlc!5e0!3m2!1sen!2sro!4v1680599498520!5m2!1sen!2sro"
                           allowFullScreen
                           loading="lazy"
                           referrerPolicy="no-referrer-when-downgrade"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-md-12">
                  <div className="row">
                     <div className="col-md-6 align-self-center">
                        <div id="allsocial" className="all_social">
                           <div className="c_title get_social_title">
                              <h2>Get Social</h2>
                           </div>
                           <div className="all_icons">
                              <a className="cursor_p" href="https://www.youtube.com/@EnergizeGames">
                                 <img src={require("assets/images/footer/icon_youtube.webp")} alt="youtube_icon" />
                              </a>
                              <a className="cursor_p" href="https://www.facebook.com/energize.games/">
                                 <img src={require("assets/images/footer/icon_fb.webp")} alt="facebook_icon" />
                              </a>
                              <a className="cursor_p" href="https://www.instagram.com/energize_games/">
                                 <img src={require("assets/images/footer/icon_insta.webp")} alt="instagram_icon" />
                              </a>
                              <a className="cursor_p">
                                 <img src={require("assets/images/footer/icon_tiktok.webp")} alt="tiktok_icon" />
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="map_con" id="lines">
                           <p>
                              <span>Tube stations: </span>Highbury and Islington (Victoria Line) & Angel Station (Northern Line)
                           </p>
                           <p>
                              <span>Buses: </span>4, 19, 30, 43 (St. Mary's Church)
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-xl-12 text-center" id="copyright">
                  <div className="copyright">
                     <p>
                        Energize Copyright © 2023. All Rights Reserved<a className="mr-0">|</a>
                        <a className="cursor_p" href="https://www.dancovision.com/">
                           Web design & Web development by Danco Vision
                        </a>
                        <a className="cursor_p" href={dataProtection} target='_blank' >
                           Confidentiality Policy</a> |<a className="cursor_p" href={termsAndConditions} target='_blank'>Terms and Conditions</a>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Footer;
