import React, { useState } from "react";
import ModalVideo from "components/ReactVideoModal";

function RoomTemplateSecond({ game, lastGame, even }) {
   const [isOpen, setOpen] = useState(false);
   const getClassFirst = `rooms_default_area margin_class overlay section_padding2 bg-${game.id}-room`;
   const getClassText = game.id > 3 ? "d_text_img text3" : "d_text_img";
   const getImage = (game, name) => {
      if (even && name === "s1") return <img src={require("../assets/images/room/2/" + name + ".webp")} alt="" />;
      if (even && name === "s2") return <img src={require("../assets/images/room/2/" + name + ".webp")} alt="" />;
      try {
         return <img src={require("../assets/images/room/" + game.id + "/" + name + ".webp")} alt="" />;
      } catch (err) {
         console.log(err, "err getimage");
      }
   };

   const getShape = () => {
      if (game.id === 1) {
         return (
            <div className={"shap_position"}>
               <img src={require("../assets/images/room/1/s_bottom.webp")} alt="shap_position_last" />
            </div>
         );
      } else {
         return (
            <div className={lastGame.id === game.id ? "shap_position shap_position_last" : "shap_position"}>
               <img src={require("../assets/images/room/1/s_bottom.webp")} alt="" />
            </div>
         );
      }
   };

   return (
      <div className={getClassFirst}>
         {/*<ModalVideo channel="youtube" isOpen={isOpen} videoId={game.videoUrl} onClose={() => setOpen(false)} />*/}
         <div className="container">
            <div className="row">
               <div className="col-lg-7">
                  <div className="default_content_full">
                     <div className="default_top default_top2">
                        <div className="text">
                           <div className="img">{getImage(game, "s1")}</div>
                           <div className={getClassText}>{getImage(game, "text")}</div>
                           <div className="de_position de_position2">{getImage(game, "s2")}</div>
                        </div>
                        <div className="de_left">{getImage(game, "img1")}</div>
                     </div>
                     <div className="de_content">
                        <p>{game.description}</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-5 cursor_p">
                  <div className="default_video">
                     <div onClick={() => setOpen(true)}>{getImage(game, "video")}</div>
                  </div>
               </div>
            </div>
         </div>
         {lastGame.id === game.id ? getShape() : null}
      </div>
   );
}
export default RoomTemplateSecond;
