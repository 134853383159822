import React from "react";
import { useNavigate } from "react-router-dom";

function RecoverPasswordSuccess() {
   const router = useNavigate();
   return (
      <div className="container recoverpasswordsuccess-container">
         <div className="row">
            <div className="password-reset text-center">
               <img src={require("assets/images/recoverPassword/your_password_was_reset_title_text-min-test.webp")} alt="" />
               <div className="col-md-12">
                  <div className="login_single login_btn register_btn register_btn_sub buttonAccount">
                     <img src={require("assets/images/buttons/BTN_back_to_homepage-min.webp")} alt="" onClick={() => router("/")} />
                  </div>
               </div>
               <p className="or_text">or</p>
               <div className="col-md-12">
                  <div className="login_single login_btn register_btn register_btn_sub buttonAccount">
                     <img src={require("assets/images/buttons/BTN_book_now-min.webp")} alt="" onClick={() => window.location.replace("/booking")} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default RecoverPasswordSuccess;
