import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import RecoverPasswordConfirmation from "pages/Auth/components/RecoverPasswordConfirmation";

function RecoverPassword() {
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const [finalizeStep, setShowFinalize] = useState(false);

   const handleRecoverPassword = data => {
      setShowFinalize(true);
   };

   return (
      <>
         <Header />
         {finalizeStep === true ? (
            <RecoverPasswordConfirmation />
         ) : (
            <div className="section_padding bg-recoverPassword">
               <div className="container recoverpassword-container">
                  <div className="row">
                     <div className="fr_title mb-0 mx-auto">
                        <img
                           className="recoverPassword_web"
                           src={require("assets/images/recoverPassword/recover_password_title_text_web-min-test.webp")}
                           alt=""
                        />
                        <img
                           className="recoverPassword_mobile"
                           src={require("assets/images/recoverPassword/recover_password_title_text_mobile-min-test.webp")}
                           alt=""
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="text"
                              {...register("nickname", {
                                 required: "Please fill in this field correctly!",
                              })}
                              className={`${errors.nickname ? "is-invalid" : "is-valid"}`}
                              placeholder="nickname"
                           />
                           <p className="error-message">{errors.nickname && errors.nickname.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="email"
                              {...register("email", {
                                 required: "Please fill in this field correctly!",
                                 pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Invalid email address",
                                 },
                              })}
                              className={`${errors.email ? "is-invalid" : "is-valid"}`}
                              placeholder="email address"
                           />
                           <p className="error-message">{errors.email && errors.email.message}</p>
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="login_single login_btn register_btn register_btn_sub buttonAccount recoverpasswordbtn">
                           <img
                              src={require("assets/images/buttons/BTN_recover_password-min.webp")}
                              alt=""
                              onClick={handleSubmit(handleRecoverPassword)}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default RecoverPassword;
