import React from "react";
import { Helmet } from "react-helmet";

export default function PageMeta({ title, content }) {
   return (
      <Helmet>
         <title>{title}</title>
         <meta name="description" content={content} />
      </Helmet>
   );
}
