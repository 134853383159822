import React from "react";

function AccountSummaryCompetitive({ nicknameState, accountSummaryData }) {
   return (
      <div className="top_rank_con account_summary_2">
         <div className="top_rank_left">
            <h3 className="comp_name_h3">{nicknameState}</h3>
         </div>
         <div className="top_rank_right2">
            <h3 className="total_comp_h3">Total Competitive Game Wins All Time</h3>
            <h4>{accountSummaryData.totalWins}</h4>
         </div>
      </div>
   );
}

export default AccountSummaryCompetitive;
