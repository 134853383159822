import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import React from "react";
import termsAndConditions from "assets/Terms and Conditions Energize.pdf";
import dataProtection from "assets/DataProtectionEnergize.pdf";

function ModalSelectLocation({ showModal, handleModal, setLocation }) {
   const router = useNavigate();
   const content = {
      content: {
         backgroundColor: "rgba(25,126,56,1)",
         boxShadow: "inset 0 0 2vw #000, 0 0 2vw #000",
         color: "#fff",
         fontSize: "19px",
         fontStyle: "italic",
         position: "fixed",
         inset: "0",
         padding: 0,
      },
   };

   return (
      <Modal isOpen={showModal} className="overrideScroll" ariaHideApp={false} style={content} contentLabel="Locations">
         <div className="pin-modal-background">
            <img src={require("assets/images/locations/Locations_page_bg_pin.webp")} alt="pin_icon" />
         </div>
         <div className="container">
            <div className="col-md-12 mx-auto text-center">
               <img
                  onClick={() => handleModal()}
                  className="modal-location-image cursor_p"
                  src={require("assets/images/Logo_Popup-min.webp")}
                  alt="logo"
               />
            </div>
            <div className="row modal-location-all" style={{ textAlign: "center" }}>
               <div className="col-md-6 modal-location-title">
                  <div className="col-md-12">
                     <h2>UK</h2>
                  </div>
                  <div className="col-md-12">
                     <h3
                        onClick={() => {
                           handleModal();
                           setLocation("LONDON");
                        }}
                     >
                        London
                     </h3>
                  </div>
               </div>

               <div className="col-md-6 modal-location-title">
                  <div className="col-md-12">
                     <h2>Romania</h2>
                  </div>
                  <div
                     onClick={() => {
                        handleModal();
                        // setLocation("BUCHAREST");
                        router("/coming-soon");
                     }}
                     className="col-md-12"
                  >
                     <h3>Bucharest</h3>
                  </div>
                  <div
                     onClick={() => {
                        handleModal();
                        // setLocation("TIMISOARA");
                        router("/coming-soon");
                     }}
                     className="col-md-12"
                  >
                     {/*<h3>Iasi</h3>*/}
                  </div>
               </div>
            </div>

            <div className="col-md-5 mx-auto">
               <div className="modal-location-icons text-center">
                  <a className="cursor_p" href="https://www.youtube.com/@EnergizeGames">
                     <img src={require("assets/images/footer/icon_youtube.webp")} alt="youtube_icon" />
                  </a>
                  <a className="cursor_p" href="https://www.facebook.com/energize.games/">
                     <img src={require("assets/images/footer/icon_fb.webp")} alt="facebook_icon" />
                  </a>
                  <a className="cursor_p" href="https://www.instagram.com/energize_games/">
                     <img src={require("assets/images/footer/icon_insta.webp")} alt="instagram_icon" />
                  </a>
                  <a className="cursor_p">
                     <img src={require("assets/images/footer/icon_tiktok.webp")} alt="tiktok_icon" />
                  </a>
               </div>
            </div>

            <div className="row mx-auto">
               <div className="col-xl-12">
                  <div className=" modal-location-copyright">
                     <p>
                        Energize Copyright © 2023. All Rights Reserved | <a  href={dataProtection} target='_blank' >Confidentiality Policy</a> | <a href={termsAndConditions} target='_blank'>Terms and Conditions</a>

                     </p>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   );
}

export default ModalSelectLocation;
