import React from "react";

function RecoverPasswordConfirmation() {
   return (
      <div className="section_padding bg-recoverPassword">
         <div className="container">
            <div className="row">
               <div className="col-xl-12 fr_title">
                  <div className="recover_password_title">
                     <img
                        className="recoverPassword_web"
                        src={require("assets/images/recoverPassword/recover_password_title_text_web-min-test.webp")}
                        alt=""
                     />
                     <img
                        className="recoverPassword_mobile"
                        src={require("assets/images/recoverPassword/recover_password_title_text_mobile-min-test.webp")}
                        alt=""
                     />
                     <p>
                        You have requested a password recovery! A confirmation email has been sent to your email address, please check your inbox and
                        follow the instructions
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default RecoverPasswordConfirmation;
