import axios from "axios";
// import { API_URL } from "constants/api.constants";
import { getCurrentUser } from "helpers/helpers";

export const RequestMethod = {
   GET: "Get",
   POST: "Post",
   PUT: "Put",
   DELETE: "Delete",
};

export async function requestJson(path, method, options) {
   try {
      const response = await executeRequest(path, method, options);
      return { response: response, isSuccess: true, error: null };
   } catch (ex) {
      // TODO bogdanla: Log to Appinsights or something
      console.error(ex);
      return { response: null, isSuccess: false, error: ex };
   }
}

async function executeRequest(path, method, options) {
   const url = path;
   const headers = getHeaders(options);
   let response;
   switch (method) {
      case RequestMethod.GET:
         response = await axios.get(jsonToUrl(url, options?.data), { headers: headers });
         break;
      case RequestMethod.POST:
         response = await axios.post(url, options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.PUT:
         response = await axios.post(url, options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.DELETE:
         response = await axios.delete(url, options?.data, {
            headers: headers,
         });
         break;
   }

   return response?.data;
}

function getHeaders(options) {
   let headers = {};

   if (options?.useAccessToken) {
      const token = getCurrentUser();
      headers["Authorization"] = `Bearer ${token}`;
   }

   if (options?.headers) {
      options.headers.forEach(header => {
         headers[header.name] = header.value;
      });
   }

   return headers;
}

function jsonToUrl(url, data) {
   if (data) {
      const urlData = Object.keys(data)
         .map(k => {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
         })
         .join("&");

      return `${url}?${urlData}`;
   }

   return url;
}
