import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useToken from "components/useToken";
import { login, getCardUid } from "api/api";
import { toast } from "react-toastify";

export default function Login() {
   const router = useNavigate();
   const { setToken, setNickname, setCardUid } = useToken();
   const [errorCoded, setErrorCoded] = useState("");

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const handleLogin = async data => {
      const response = await login(data);
      if (response.response.status.code === 100) {
         setErrorCoded("hardcoded to show error on login field");
         toast.error("Invalid username or password");
      } else {
         setToken(response.response.data[0].token);
         setNickname(data.nickName);
         setCardUid(await getCardUid(data.nickName));
         router("/account");
         console.log("login successful");
      }
   };

   return (
      <>
         <div className="log_in logIn_subtitle">
            <img src={require("assets/images/auth/logIn_button_text-min-test.webp")} alt="" />
         </div>

         <form>
            <div className="row">
               <div className="col-md-6">
                  <div className="login_single single_fra_input">
                     <input
                        type="text"
                        {...register("nickName", {
                           required: "Please fill in this field correctly!",
                        })}
                        className={`${errors.nickName || errorCoded !== "" ? "is-invalid" : "is-valid"}`}
                        placeholder="nickname"
                     />
                     {errorCoded !== "" ? (
                        <p className="error-message">Invalid username or password</p>
                     ) : (
                        <p className="error-message">{errors.nickName?.message}</p>
                     )}
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="login_single single_fra_input">
                     <input
                        type="password"
                        {...register("password", {
                           required: "Please fill in this field correctly!",
                           minLength: { value: 2, message: "Min length is 2" },
                        })}
                        className={`${errors.password || errorCoded !== "" ? "is-invalid" : "is-valid"}`}
                        placeholder="password"
                     />
                     {errorCoded !== "" ? (
                        <p className="error-message">Invalid username or password</p>
                     ) : (
                        <p className="error-message">{errors.nickName?.message}</p>
                     )}
                  </div>
               </div>
            </div>

            <div className="login_single single_fra_input">
               <a className="forgot_password" onClick={() => router("/recover-password")}>
                  Forgot your password? Click to recover
               </a>
            </div>

            <input
               type="image"
               className="w-img2 bg-transparent"
               src={require("assets/images/buttons/login_btn-min.webp")}
               alt=""
               onClick={handleSubmit(handleLogin)}
            />
         </form>
      </>
   );
}
