import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import ContainerCheckScore from "components/CheckScores/ContainerCheckScore";
import SearchScore from "components/CheckScores/SearchScore";
import useToken from "components/useToken";
import { login } from "api/api";
import { dataToLoginForCheckScores } from "constants/checkScore.constants";
import { isUserAuthenticated } from "helpers/helpers";
import PageMeta from "components/PageMeta";

function CheckScores() {
   const [isCooperative, setIsCooperative] = useState(true);
   const { nickname, cardUid, setToken } = useToken();
   const [cardUidState, setCardUidState] = useState(cardUid);
   const [nicknameState, setNicknameState] = useState(nickname);
   const [isAuth, setIsAuth] = useState(false);

   useEffect(() => {
      if (isUserAuthenticated()) setIsAuth(true);
      else {
         setIsAuth(false);
         const loginAsync = async () => {
            const response = await login(dataToLoginForCheckScores);
            setToken(response.response.data[0].token);
            setIsAuth(true);
         };
         loginAsync();
      }
   }, []);

   return (
      <>
         <PageMeta
            title={"Arcade style active games Checkscores ▷  Energize"}
            content={
               "Looking to track your progress and see how you stack up against other players at Energy arcade style active games? Check out our Checkscores feature!"
            }
         />
         <Header />
         <div className="section_padding bg-checkScores">
            <div className="container checkscores-container">
               <div className="col-md-12 check_scores_title pl-0">
                  <h1>Check Scores</h1>
                  <p className="mb-3">Enter your nickname below and check your scores!</p>
                  <p className="mb-3">
                     Energize tracks every game that you have played in both Cooperative and Competitive mode and shows you your top scores for each
                     game as well as your Energize Token Ranking.
                  </p>
                  <p className="mb-4">
                     Earn Energize Tokens by playing new games and beating new levels in Cooperative, then claim your prizes! The more tokens you win,
                     the higher your Rank and your rewards.
                  </p>
               </div>

               <div className="row">
                  <div className="col-lg-12">
                     <div className="room_page_content">
                        {isAuth && (
                           <>
                              <SearchScore setCardUidState={setCardUidState} setNicknameState={setNicknameState} />
                              <div className="all_find">
                                 <div className="find_tab">
                                    <ul className="nav nav-tabs cursor_p color-white" role="tablist">
                                       <li role="presentation">
                                          <a
                                             className={isCooperative ? "active font" : "no-active-green"}
                                             aria-controls={1}
                                             role="tab"
                                             data-toggle="tab"
                                             onClick={() => setIsCooperative(true)}
                                          >
                                             Collaborative
                                          </a>
                                       </li>
                                       <li role="presentation">
                                          <a
                                             className={!isCooperative ? "active" : "no-active-red"}
                                             aria-controls={2}
                                             role="tab"
                                             data-toggle="tab"
                                             onClick={() => setIsCooperative(false)}
                                          >
                                             Versus Mode
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                                 <ContainerCheckScore isCooperative={isCooperative} nicknameState={nicknameState} cardUidState={cardUidState} />
                              </div>
                           </>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default CheckScores;
