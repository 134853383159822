import React from "react";
import { useNavigate } from "react-router-dom";
import { goToTop } from "helpers/helpers";

function ButtonBook({ title, navigateTo, customStyle, doesNavigate }) {
   const router = useNavigate();

   return (
      <>
         {doesNavigate && navigateTo === "/booking" ? (
            <div
               onClick={() => {
                  goToTop();
                  window.location.replace("/booking");
               }}
               className="button_book_now"
            >
               <a className={`${customStyle}`}>{title}</a>
            </div>
         ) : doesNavigate && navigateTo === "/gift-voucher-purchase" ? (
            <div
               onClick={() => {
                  goToTop();
                  window.location.replace("/gift-voucher-purchase");
               }}
               className="button_book_now"
            >
               <a className={`${customStyle}`}>{title}</a>
            </div>
         ) : doesNavigate ? (
            <div onClick={() => goToTop()} className="button_book_now">
               <a onClick={() => router(`${navigateTo}`)} className={`${customStyle}`}>
                  {title}
               </a>
            </div>
         ) : (
            <div className="button_book_now p-0">
               <a className={`pl-5 pr-5 ${customStyle}`}>{title}</a>
            </div>
         )}
      </>
   );
}

export default ButtonBook;
