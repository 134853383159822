import React, { useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import useToken from "components/useToken";

function Account() {
   const { logout, nickname } = useToken();
   const router = useNavigate();

   const handleLogout = () => {
      logout();
      router("/");
   };

   useEffect(() => {
      if (!nickname) router("/loginregister");
   }, [nickname]);

   return (
      <>
         <Header />
         <div className="section_padding bg-account">
            <div className="container account-container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="fr_title">
                        <img className="account_web" src={require("assets/images/account/youAreLoggedInAs_title_text_web-min-test.webp")} alt="" />
                        <img
                           className="account_mobile"
                           src={require("assets/images/account/youAreLoggedInAs_title_text_mobile-min-test.webp")}
                           alt=""
                        />
                        <p className="subtitle_text">{nickname}</p>
                        <div className="row">
                           <div className="col-md-6 first-change">
                              <div className="button_book_now p-0">
                                 <a className="account-page-btn first-change" onClick={() => router("/change-email")}>
                                    Change
                                    <br className="account-br" /> <span className="color_cff3b1">Email Address</span>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6 second-change second-change-BTN">
                              <div className="button_book_now p-0">
                                 <a className="account-page-btn second-change" onClick={() => router("/change-password")}>
                                    Change
                                    <br className="account-br" /> <span className="color_cff3b1">Password</span>
                                 </a>
                              </div>
                           </div>
                        </div>

                        <div className="login_single login_btn register_btn register_btn_sub buttonAccount">
                           <img src={require("assets/images/buttons/BTN_LogOut-min.webp")} alt="" onClick={() => handleLogout()} />
                        </div>
                        <div className="login_single login_btn register_btn register_btn_sub buttonAccount mt-1">
                           <img src={require("assets/images/buttons/BTN_Check_Your_Scores-min.webp")} alt="" onClick={() => router("/checkscores")} />
                        </div>
                        <p className="or_text">or</p>
                        <div className="login_single login_btn register_btn register_btn_sub buttonAccount mt-3">
                           <img
                              src={require("assets/images/buttons/BTN_Acc_BookNow-min.webp")}
                              alt=""
                              onClick={() => window.location.replace("/booking")}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default Account;
