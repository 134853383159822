import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import useToken from "components/useToken";
import Login from "pages/Auth/components/Login";
import Register from "pages/Auth/components/Register";
import RegisterComplete from "pages/Auth/components/RegisterComplete";
import { goToTop } from "helpers/helpers";

function LoginRegister() {
   const router = useNavigate();
   const registerRef = useRef(null);
   const topRef = useRef(null);
   const { token, nickname } = useToken();

   const [showRegister, setShowRegister] = useState(false);
   const [finalizeStep, setShowFinalize] = useState({
      enabled: false,
      nickname: null,
      hashPassword: null,
   });

   const showRegisterBtnClick = () => {
      setShowRegister(!showRegister);
      registerRef.current?.scrollIntoView({ behavior: "smooth" });
   };

   // TODO bogdanla: Improve OnRegisterFinalized
   const onRegisterFinalized = (nickname, hashPassword) => {
      goToTop();
      setShowFinalize({
         enabled: true,
         nickname: nickname,
         hashPassword: hashPassword,
      });
   };

   useEffect(() => {
      if (token && nickname) router("/account");
   }, [token, router]);

   return (
      <>
         <Header />
         <div className=" section_padding bg-auth" ref={topRef}>
            <div className="container auth-container">
               <div className="fr_title fr_title2 text-center login-register-title">
                  <img src={require("assets/images/auth/loginRegister_title_text-min-test.webp")} alt="" />
               </div>

               {finalizeStep.enabled ? (
                  <RegisterComplete nickname={finalizeStep.nickname} hashPassword={finalizeStep.hashPassword} />
               ) : (
                  <>
                     <Login />
                     <div className="register">
                        <div className="login_single single_fra_input cursor_p">
                           <a className="missing_account" onClick={() => showRegisterBtnClick()}>
                              Don't have an account?
                           </a>
                        </div>

                        <div className="login_single login_btn register_btn registerNowBTN">
                           <img
                              ref={registerRef}
                              onClick={() => showRegisterBtnClick()}
                              src={require("assets/images/buttons/registernow_btn_crop-min.webp")}
                              alt=""
                           />
                        </div>

                        {showRegister && <Register onRegisterFinalized={onRegisterFinalized} />}
                     </div>
                  </>
               )}
            </div>
         </div>
         <div className="divider_img" style={{ zIndex: 0 }} />
         <Footer />
      </>
   );
}

export default LoginRegister;
