import React, { useState, useEffect, useContext } from "react";
import { ScrollContext } from "App";
import { useNavigate } from "react-router-dom";
import useToken from "components/useToken";
import ModalSelectLocation from "components/ModalSelectLocation";
import { goToTop } from "helpers/helpers";

function Header() {
   const router = useNavigate();
   const { nickname, location, setLocation } = useToken();
   const { setScrollToAboutUs } = useContext(ScrollContext);
   const [showModal, setShowModal] = useState(false);
   const [isChecked, setIsChecked] = useState(false);

   const handleModal = () => {
      setShowModal(false);
   };

   const handleCheckedMenu = () => {
      setIsChecked(!isChecked);
   };

   const clickEventListenerMenu = e => {
      let menu = document.getElementById("menu");
      let menuToggle = document.getElementById("menuToggle");
      if (!menu.contains(e.target) && !menuToggle.contains(e.target)) {
         if (isChecked) setIsChecked(false);
      }
   };

   const aboutUsClickHandler = () => {
      handleCheckedMenu();
      router("/");
      setScrollToAboutUs(true);
   };

   useEffect(() => {
      window.addEventListener("click", clickEventListenerMenu);
      return () => window.removeEventListener("click", clickEventListenerMenu);
   }, [isChecked]);

   let locationInitials = "UK";

   // switch (location) {
   //    case "LONDON":
   //    case "LIVERPOOL":
   //    case "MANCHESTER": {
   //       locationInitials = "UK";
   //       break;
   //    }
   //    case "PARIS": {
   //       locationInitials = "FR";
   //       break;
   //    }
   //    case "TIMISOARA":
   //    case "BUCHAREST": {
   //       locationInitials = "RO";
   //       break;
   //    }
   //    default: {
   //       locationInitials = "s";
   //       break;
   //    }
   // }

   return (
      <header className="header_area_design">
         <div className="container-fluid">
            <div className="row">
               <div className="col-xl-12">
                  <div className="header_all">
                     <div className="logo cursor_p">
                        <a onClick={() => router("/")}>
                           <img src={require("assets/images/header/logo_energize_header-min-test.webp")} alt="logo" />
                        </a>
                     </div>
                     <div className="row">
                        <h3 className="menu_text ">Menu</h3>
                        <div className="menu_option">
                           <nav role="navigation">
                              <div id="menuToggle">
                                 <input type="checkbox" checked={isChecked} onChange={() => handleCheckedMenu()} />
                                 <span />
                                 <span />
                                 <span />
                                 <ul id="menu">
                                    <img src={require("../assets/images/header/x.webp")} alt="close_icon" />
                                    {nickname && (
                                       <div className="row row_menu cursor_p user_icon">
                                          <img src={require("assets/images/header/user_icon.png")} alt="user_icon" />
                                          <a onClick={() => (router("/account"), goToTop())} className="menu_nickname">
                                             <li>Welcome {nickname}</li>
                                          </a>
                                       </div>
                                    )}
                                    <a onClick={() => setShowModal(true)}>
                                       <div className="row row_menu cursor_p">
                                          {location === null ? (
                                             <li style={{ color: "black" }}>Locations</li>
                                          ) : (
                                             <li style={{ color: "black" }}>
                                                {location}, {locationInitials}
                                             </li>
                                          )}
                                          <i
                                             style={{ color: "black", alignSelf: "center", marginLeft: "12px" }}
                                             className="fa fa-map-marker"
                                             aria-hidden="true"
                                          />
                                       </div>
                                    </a>
                                    <ModalSelectLocation showModal={showModal} handleModal={handleModal} setLocation={setLocation} />
                                    <a className="cursor_p" onClick={() => aboutUsClickHandler()}>
                                       <li>About energize</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (window.location.replace("/booking"), goToTop())}>
                                       <li>Book now</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/prices"), goToTop())}>
                                       <li>Prices</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/rooms"), goToTop())}>
                                       <li>See rooms</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/faq"), goToTop())}>
                                       <li>FAQ</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/events"), goToTop())}>
                                       <li>Corporate & parties</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/gift-voucher"), goToTop())}>
                                       <li>Gift vouchers</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/checkscores"), goToTop())}>
                                       <li>Scores</li>
                                    </a>
                                    {nickname ? (
                                       <>
                                          <a className="cursor_p" onClick={() => (router("/account"), goToTop())}>
                                             <li style={{ color: "white" }}>Account</li>
                                          </a>
                                       </>
                                    ) : (
                                       <>
                                          {/*<a className="cursor_p" onClick={() => (router("/loginregister"), goToTop())}>*/}
                                          {/*   <li>Login/Register</li>*/}
                                          {/*</a>*/}
                                       </>
                                    )}

                                    <a
                                       className="cursor_p"
                                       onClick={() => (
                                          handleCheckedMenu(), document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
                                       )}
                                    >
                                       <li>Contact us</li>
                                    </a>
                                    <a className="cursor_p" onClick={() => (router("/franchise"), goToTop())}>
                                       <li>Franchise</li>
                                    </a>
                                 </ul>
                              </div>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>
   );
}

export default Header;
