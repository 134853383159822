import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import PageMeta from "components/PageMeta";

function Prices() {
   return (
      <>
         <PageMeta
            title={"Arcade style active games price room ✔️ Fun with Energize"}
            content={
               "Check out our interactive arcade style active games rooms at an affordable price! With multiple games to choose from, there's something for everyone!"
            }
         />
         <Header />
         <div className="bg-prices section_padding">
            <div className="container prices-container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="price_title text-center">
                        <img src={require("assets/images/prices/prices_title_text-min-test.webp")} alt="" />
                     </div>
                  </div>
               </div>

               <div className="col-md-12">
                  <div className="col-md-6 mx-auto pl-0 pr-0">
                     <div className="price_week-days">
                        <h2>Monday - Friday</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="price_hours-left row">
                           <img src={require("assets/images/footer/icon_ceas.webp")} />
                           <h3>10:00 - 16:00</h3>
                        </div>
                        <div className="price_hours_container prices_icons_single text-center rounded-0">
                           <img src={require("assets/images/prices/prices_5_users_icon-min.webp")} alt="" />
                           <h3>3-5 players</h3>
                           <h2>£33/player</h2>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="price_hours-right row">
                           <img src={require("assets/images/footer/icon_ceas.webp")} />
                           <h3>16:00 - 22:00</h3>
                        </div>

                        <div className="price_hours_container prices_icons_single text-center">
                           <img src={require("assets/images/prices/prices_5_users_icon-min.webp")} alt="" />
                           <h3>3-5 players</h3>
                           <h2>£39/player</h2>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-md-12" style={{ marginTop: "70px" }}>
                  <div className="row">
                     <div className="col-md-12 single-card-price">
                        <div className="price_weekend-days col-md-8 mx-auto">
                           <h2>Saturday - Sunday</h2>
                        </div>

                        <div className="price_hours row">
                           <img src={require("assets/images/footer/icon_ceas.png")} />
                           <h3>09:00 - 22:00</h3>
                        </div>

                        <div className="price_hours_container prices_icons_single text-center single-card-price-players single-icon-price">
                           <img src={require("assets/images/prices/prices_5_users_icon-min.webp")} alt="" />
                           <h3>3-5 players</h3>
                           <h2>£39/player</h2>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="prices-student-description">
                  <p>
                     STUDENT DISCOUNT - Monday to Thursday all day. Use code STUDENT15 to get 15% off. Each player must show valid student ID upon
                     arrival.
                  </p>
               </div>
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default Prices;
