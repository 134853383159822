import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import RoomTemplateFirst from "components/RoomTemplateFirst";
import RoomTemplateSecond from "components/RoomTemplateSecond";
import games from "data/games";
import PageMeta from "components/PageMeta";

function Rooms() {
   const lastGame = games[games.length - 1];
   return (
      <>
         <PageMeta
            title={"Arcade style active games Rooms ▷ Discover Energize in London"}
            content={
               "Enter a world of excitement and adventure in our arcade style active games rooms! Our fully immersive environments provide an electrifying experience for all ages."
            }
         />
         <Header />

         {games.map((game, index) => {
            if (game.type === "odd") return <RoomTemplateFirst key={index} game={game} lastGame={lastGame} odd={true} />;
            else return <RoomTemplateSecond key={index} game={game} lastGame={lastGame} even={true} />;
         })}
         <Footer />
      </>
   );
}
export default Rooms;
