import React from "react";
import { getCardUid } from "api/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function SearchScore({ setCardUidState, setNicknameState }) {
   const {
      register,
      handleSubmit,
      trigger,
      formState: { errors },
   } = useForm();
   const onSubmit = data => {
      onSubmitSearch(data);
   };

   const onSubmitSearch = async data => {
      const response = await getCardUid(data.search);
      if (response === "Card not found!") toast.error("No user with this nickname found");
      else {
         setCardUidState(await getCardUid(data.search));
         setNicknameState(data.search);
      }
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className="t_search">
            <div className="single_g_search single_g_search1">
               <input placeholder="Enter nickname or email" {...register("search", { required: true })} />
               <div className="input_position">
                  <img src={require("assets/images/games/man.png")} alt="user_icon" />
               </div>
            </div>
            <button
               type="submit"
               className="single_g_search single_g_search2 align-self-center button-no-style"
               onClick={async () => {
                  const result = await trigger("search", { shouldFocus: true });
                  if (!result) toast.error("Please search for a nickname first!");
               }}
            >
               <div className="button_book_now p-0">
                  <a className="pl-5 pr-5 button-search-score">Search</a>
               </div>
            </button>
            <input type="submit" className="d-none" />
         </div>
      </form>
   );
}

export default SearchScore;
