import React, { useState, useEffect } from "react";
import AccountSummaryCoopCard from "components/CheckScores/AccountSummaryCoopCard";
import { getAccountSummaryPrizes, getAccountSummaryCurrentCardRank } from "api/api";
import { getGameType } from "helpers/helpers";

export const AccountSummaryRankType = {
   Previous: "PREVIOUS RANK",
   Current: "CURRENT RANK",
   Next: "NEXT RANK",
};
export const AccountSummaryCardTheme = {
   Grey: {
      backgroundImageCard: `url(${require("assets/images/games/p_g.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/p_n.png")})`,
      rewardTextColor: "#BDBDBD",
   },
   Violet: {
      backgroundImageCard: `url(${require("assets/images/games/g1.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n1.png")})`,
      rewardTextColor: "#00E646",
   },
   Orange: {
      backgroundImageCard: `url(${require("assets/images/games/g2.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n2.png")})`,
      rewardTextColor: "#FEB14E",
      lockIcon: require("assets/images/games/i2.png"),
   },
   Blue: {
      backgroundImageCard: `url(${require("assets/images/games/g3.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n3.png")})`,
      rewardTextColor: "#548DFF",
      lockIcon: require("assets/images/games/i3.png"),
   },
   Red: {
      backgroundImageCard: `url(${require("assets/images/games/g4.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n4.png")})`,
      rewardTextColor: "#FE5959",
      lockIcon: require("assets/images/games/i4.png"),
   },
   Yellow: {
      backgroundImageCard: `url(${require("assets/images/games/g5.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n5.png")})`,
      rewardTextColor: "#FFF780",
      lockIcon: require("assets/images/games/i5.png"),
   },
   Green: {
      backgroundImageCard: `url(${require("assets/images/games/g6.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n6.png")})`,
      rewardTextColor: "#00E646",
      lockIcon: require("assets/images/games/i6.png"),
   },
   Pink: {
      backgroundImageCard: `url(${require("assets/images/games/g7.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n7.png")})`,
      rewardTextColor: "#FF66D8",
      lockIcon: require("assets/images/games/i7.png"),
   },
   Cyan: {
      backgroundImageCard: `url(${require("assets/images/games/g8.png")})`,
      backgroundImageNumber: `url(${require("assets/images/games/n8.png")})`,
      rewardTextColor: "#66FFE5",
      lockIcon: require("assets/images/games/i8.png"),
   },
};

function AccountSummaryCooperative({ isCooperative, energizeToken, nicknameState, accountSummaryData, cardUidState }) {
   const [prizes, setPrizes] = useState([]);
   const [currentCardRank, setCurrentCardRank] = useState(0);

   useEffect(() => {
      const getAccountSummaryCurrentCardRankAsync = async () => {
         const response = await getAccountSummaryCurrentCardRank(cardUidState);
         setCurrentCardRank(response);
      };
      const getAccountSummaryPrizesAsync = async () => {
         const gameType = getGameType(isCooperative);
         const response = await getAccountSummaryPrizes(gameType, nicknameState);
         setPrizes(response);
      };
      getAccountSummaryCurrentCardRankAsync();
      getAccountSummaryPrizesAsync();
   }, [nicknameState, isCooperative]);

   return (
      <div role="tabpanel" className="tab-pane active" id="d2">
         <div className="account_summary_all">
            <div
               className="acc_top"
               style={{
                  backgroundImage: `url(${require("assets/images/games/summary_bg.png")})`,
               }}
            >
               <h2>{nicknameState}</h2>
               <div className="flex_acc">
                  <div className="a_logo">
                     <img src={require("assets/images/games/logo2.png")} alt="logo" />
                  </div>
                  <div className="a_con">
                     <h4>{energizeToken}</h4>
                     <h5>Energize Tokens</h5>
                  </div>
               </div>
               <div className="a_bottom">
                  <p>
                     <span className="first_c">Energize Token Rank</span>
                     <span>{currentCardRank}</span>
                  </p>
                  <p>
                     <span className="first_c">Total Score</span>
                     <span>{accountSummaryData.score}</span>
                  </p>
                  <p>
                     <span className="first_c">Leaderboard position</span>
                     <span>{accountSummaryData.rank}</span>
                  </p>
                  <p>
                     <span className="first_c">Levels</span>
                     <span>
                        {accountSummaryData.playedLevels}/{accountSummaryData.totalLevels}
                     </span>
                  </p>
               </div>
            </div>

            {prizes.map((prize, index) => {
               const colors = ["Violet", "Orange", "Blue", "Red", "Yellow", "Green", "Pink", "Cyan"];
               return (
                  <AccountSummaryCoopCard
                     key={prize.id}
                     number={index + 1}
                     type={
                        prize.userrank < currentCardRank
                           ? AccountSummaryRankType.Previous
                           : prize.userrank === currentCardRank
                           ? AccountSummaryRankType.Current
                           : AccountSummaryRankType.Next
                     }
                     theme={AccountSummaryCardTheme[colors[index]]}
                     energizeToken={energizeToken}
                     prizeName={prize.prize}
                     prizeTokensToGo={prize.tokensneeded}
                     currentCardRank={currentCardRank}
                  />
               );
            })}
         </div>
      </div>
   );
}

export default AccountSummaryCooperative;
