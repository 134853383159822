import React, { useState, useEffect, useCallback, useRef } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import PageMeta from "components/PageMeta";
// import useToken from "components/useToken";

function Booking() {
   const ref = useRef();
   const [height, setHeight] = useState("0px");

   const onLoad = useCallback(() => {
      // window.location.reload();
      setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
   }, [ref]);

   useEffect(() => {
      (function (d, s, id) {
         var js,
            fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) return;
         js = d.createElement(s);
         js.id = id;
         js.src = "https://energize.resova.co.uk/widget";
         fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "resova-pi");
   }, []);

   // const { location } = useToken();

   return (
      <>
         <PageMeta
            title={"Book Now arcade style active games room in London ▷ Energize"}
            content={
               "Book a thrilling adventure with your friends in arcade style active games room! Solve puzzles and complete challenges. Reserve your spot today!"
            }
         />
         <Header />
         <div className="text-center booking_voucher">
            <img className="booking_web" src={require("assets/images/booking/book_energize_london_uk_title_text_web-min-test.webp")} alt="" />
            <img className="booking_mobile" src={require("assets/images/booking/book_energize_london_uk_title_text_mobile-min-test.webp")} alt="" />
         </div>
         <div className="resova-container" id="resova-wrapper">
            <div>
               <iframe ref={ref} onLoad={onLoad} id="myFrame" src="https://energize.resova.co.uk/" height={height} scrolling="no"></iframe>
            </div>
         </div>

         <div className="divider_img" />
         <Footer />
      </>
   );
}

export default Booking;
