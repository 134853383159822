import React, { useState, useEffect } from "react";
import ContentRankings from "components/CheckScores/Rankings";
import AccountSummaryCompetitive from "components/CheckScores/AccountSummaryCompetitive";
import AccountSummaryCooperative from "components/CheckScores/AccountSummaryCooperative";
import ContentGames from "components/CheckScores/ContentGames";
import { getEnergizeToken, getAccountSummary } from "api/api";
import { getGameType } from "helpers/helpers";

function ContentCheckScore({ subTabsActive, isCooperative, selectedRoom, roomId, gameId, setGameId, nicknameState, cardUidState }) {
   const [energizeToken, setEnergizeToken] = useState("");
   const [accountSummaryData, setAccountSummaryData] = useState({});

   useEffect(() => {
      const getEnergizeTokenAsync = async () => {
         const energizeToken = await getEnergizeToken(nicknameState);
         setEnergizeToken(energizeToken);
      };
      getEnergizeTokenAsync();
   }, [nicknameState]);

   useEffect(() => {
      const getAccountSummaryAsync = async () => {
         const gameType = getGameType(isCooperative);
         const response = await getAccountSummary(gameType, nicknameState);
         setAccountSummaryData(response);
      };
      getAccountSummaryAsync();
   }, [isCooperative, nicknameState]);

   return (
      <>
         {subTabsActive === "RANKINGS" && isCooperative && <ContentRankings />}

         {subTabsActive === "ACCOUNTSUMMARY" && isCooperative && (
            <AccountSummaryCooperative
               isCooperative={isCooperative}
               energizeToken={energizeToken}
               nicknameState={nicknameState}
               cardUidState={cardUidState}
               accountSummaryData={accountSummaryData}
            />
         )}
         {subTabsActive === "ACCOUNTSUMMARY" && !isCooperative && (
            <AccountSummaryCompetitive nicknameState={nicknameState} accountSummaryData={accountSummaryData} />
         )}

         {subTabsActive === "GAMES" && (
            <ContentGames
               selectedRoom={selectedRoom}
               isCooperative={isCooperative}
               energizeToken={energizeToken}
               accountSummaryData={accountSummaryData}
               nicknameState={nicknameState}
               roomId={roomId}
               gameId={gameId}
               setGameId={setGameId}
               cardUidState={cardUidState}
            />
         )}
      </>
   );
}

export default ContentCheckScore;
