import React from "react";
import { useNavigate } from "react-router-dom";
import { finalizeRegisterApi, getCardUid } from "api/api";
import useToken from "components/useToken";

function RegisterComplete(props) {
   const router = useNavigate();
   const { setToken, setNickname, setCardUid } = useToken();

   const finalizeRegister = async () => {
      const data = {
         nickname: props.nickname,
         hashPassword: props.hashPassword,
      };
      const response = await finalizeRegisterApi(data);
      setToken(response.response.data[0].token);
      setNickname(response.response.data[0].nickname);
      setCardUid(await getCardUid(response.response.data[0].nickname));
      router("/account");
      console.log("login successful");
   };
   return (
      <div className="row">
         <div className="col-xl-12">
            <div className="register_complete_title registerComplete_title text-center">
               <img src={require("assets/images/auth/registrationConfirmed_title_text-min-test.webp")} alt="" />
               <p>Remember to confirm your email adress later</p>
               <div className="col-md-12">
                  <div className="login_single login_btn register_btn register_btn_sub buttonAccount">
                     <img src={require("assets/images/auth/button_finalize-min.webp")} onClick={() => finalizeRegister()} alt="" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default RegisterComplete;
