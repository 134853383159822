import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";

const NotFound404 = () => {
   const router = useNavigate();

   return (
      <>
         <Header />
         <div className="containter bg-notFound py-5">
            <div className="notFound text-center">
               <img className="notFoundTitle" src={require("assets/images/notFound404/404.webp")} alt="" />
               <p>Ooops, this page cannot be found!</p>
               <div className="col-md-12">
                  <div className="login_single login_btn register_btn register_btn_sub buttonReturn">
                     <img src={require("assets/images/notFound404/Buton_Return_Desktop.webp")} alt="" onClick={() => router("/")} />
                  </div>
               </div>
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
};

export default NotFound404;
