import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { registerApi } from "api/api";
import { sha512 } from "js-sha512";
import Waiver from "components/Waiver";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import { prepareDataEmail } from "helpers/PrepareData";
import { toast } from "react-toastify";

export default function Register(props) {
   const {
      register,
      watch,
      handleSubmit,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const [showModal, setShowModal] = useState(false);
   const [ticked, setTicked] = useState(false);
   const [errorCoded, setErrorCoded] = useState("");

   const handleRegister = data => {
      const hashPassword = sha512(data.passwordRegister);
      try {
         registerApi(data, response => {
            if (response.data.status.code === 0) {
               props.onRegisterFinalized(response.data.data[0].nickname, hashPassword);
               sendEmailRegisterConfirm(data);
            } else {
               //hardcoded to show error on nickNameRegister field
               setErrorCoded("hardcoded to show error on nickNameRegister field");
            }
         });
         // sendEmailRegisterConfirm(data);
      } catch (err) {
         console.log(err);
         console.log("register failed");
      }
   };
   const handleModal = () => {
      setShowModal(false);
      setTicked(true);
   };

   const sendEmailRegisterConfirm = async data => {
      const response = await requestJson("https://energize.co.uk/emails/registerConfirmation.php", RequestMethod.POST, {
         data: prepareDataEmail(data),
         headers: [{ key: "Content-Type", value: "application/json" }],
      });
      if (response.isSuccess === false) {
         console.log(response.error);
         toast.error("Email was not sent");
      }
   };

   return (
      <form>
         <div className="row">
            <div className="col-md-6">
               <div className="login_single single_fra_input single_fra_input_register">
                  <input
                     type="text"
                     {...register("nickNameRegister", {
                        required: "Please fill in this field correctly!",
                        minLength: {
                           value: 2,
                           message: "Nickname must be at least 2 characters long!",
                        },
                        maxLength: {
                           value: 12,
                           message: "Nickname must be at most 12 characters long!",
                        },
                     })}
                     className={`${errors.nickNameRegister || errorCoded !== "" ? "is-invalid" : "is-valid"}`}
                     placeholder="nickname"
                  />
                  {errorCoded ? (
                     <p className="error-message">This nickname is already taken.</p>
                  ) : (
                     <p className="error-message"> {errors.nickNameRegister && errors.nickNameRegister.message}</p>
                  )}
               </div>
            </div>
         </div>

         <div className="row">
            <div className="col-md-6">
               <div className="login_single single_fra_input single_fra_input_register">
                  <input
                     type="email"
                     {...register("emailRegister", {
                        required: "Please fill in this field correctly!",
                        pattern: {
                           value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                           message: "Invalid email address",
                        },
                     })}
                     className={`${errors.emailRegister ? "is-invalid" : "is-valid"}`}
                     placeholder="email address"
                  />
                  <p className="error-message"> {errors.emailRegister && errors.emailRegister.message}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="login_single single_fra_input single_fra_input_register">
                  <input
                     type="email"
                     {...register("confirmEmailRegister", {
                        required: "Please fill in this field correctly!",
                        pattern: {
                           value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                           message: "Invalid email address",
                        },
                        validate: value => {
                           if (watch("emailRegister") !== value) {
                              return "Your emails do not match";
                           }
                        },
                     })}
                     className={`${errors.confirmEmailRegister ? "is-invalid" : "is-valid"}`}
                     placeholder="confirm email address"
                  />
                  <p className="error-message"> {errors.confirmEmailRegister && errors.confirmEmailRegister.message}</p>
               </div>
            </div>
         </div>

         <div className="row">
            <div className="col-md-6">
               <div className="login_single single_fra_input single_fra_input_register">
                  <input
                     type="password"
                     {...register("passwordRegister", {
                        required: "Please fill in this field correctly!",
                        minLength: {
                           value: 4,
                           message: "Password must be at least 4 characters long!",
                        },
                     })}
                     className={`${errors.passwordRegister ? "is-invalid" : "is-valid"}`}
                     placeholder="password"
                  />
                  <p className="error-message"> {errors.passwordRegister && errors.passwordRegister.message}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="login_single single_fra_input single_fra_input_register">
                  <input
                     type="password"
                     {...register("confirmPasswordRegister", {
                        required: "Please fill in this field correctly!",
                        validate: value => {
                           if (watch("passwordRegister") !== value) {
                              return "Your passwords do not match";
                           }
                        },
                     })}
                     className={`${errors.confirmPasswordRegister ? "is-invalid" : "is-valid"}`}
                     placeholder="confirm password"
                  />
                  <p className="error-message"> {errors.confirmPasswordRegister && errors.confirmPasswordRegister.message}</p>
               </div>
            </div>
         </div>

         <div onClick={() => setShowModal(true)} className="row checkbox-left">
            <input
               type="checkbox"
               {...register("waiver", {
                  required: "Please accept the waiver",
               })}
               className={`${errors.waiver && ticked === false ? "checkbox-is-invalid" : "checkbox-is-valid"} checkbox-style`}
               checked={ticked}
               onChange={e => setTicked(e.target.checked)}
               id="flexCheckDefault"
            />
            <p className="link" htmlFor="">
               Tick to sign waiver
            </p>
         </div>
         <p className="error-message">{ticked === false && errors.waiver && errors.waiver.message}</p>

         <Waiver handleModal={handleModal} showModal={showModal} />

         <div className="col-md-12 text-center buttonAccount">
            <input
               type="image"
               className="w-img bg-transparent"
               onClick={handleSubmit(handleRegister)}
               src={require("assets/images/auth/button_register-min.webp")}
               alt=""
            />
         </div>
      </form>
   );
}
