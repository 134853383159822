import React, { useState, useEffect } from "react";
import { getRankingsGlobal } from "api/api";

function Rankings({}) {
   const [rankingsGlobal, setRankingsGlobal] = useState([]);

   useEffect(() => {
      const getRankingsGlobalAsync = async () => {
         const response = await getRankingsGlobal();
         setRankingsGlobal(response);
      };
      getRankingsGlobalAsync();
   }, []);

   return (
      <div role="tabpanel" className="tab-pane active" id="d1">
         <div className="ranking_divs_area">
            <div className="ranking_all">
               <h2>Leaderboard</h2>
               <table className="table table-rankings mb-0">
                  <colgroup>
                     <col className="rank-col" style={{ width: "15%" }} />
                     <col className="rank-col" />
                     <col className="rank-col" />
                  </colgroup>
                  <thead>
                     <tr>
                        <th>Position</th>
                        <th>Nickname</th>
                        <th>Score</th>
                     </tr>
                  </thead>
                  <tbody>
                     {rankingsGlobal.map(item => {
                        return (
                           <tr key={item.nickname}>
                              <td>{item.rank}</td>
                              <td>{item.nickname}</td>
                              <td>{item.score}</td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
}

export default Rankings;
