export const prepareDataEmail = data => {
   return {
      type: "newAccount",
      user: {
         nickname: data.nickNameRegister,
         email: data.emailRegister,
         password: data.passwordRegister,
      },
   };
};

export const prepareDataEmailEvents = data => {
   return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      numberAttendees: data.attendees,
      typeOfEvent: data.eventType,
      additionalMessage: data.additionalInfo,
   };
};

export const prepareDataEmailFranchise = data => {
   return {
      fullName: data.nameSurname,
      email: data.email,
      phone: data.phoneNumber,
      city: data.city,
      additionalMessage: data.additionalInfo,
   };
};
