import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { useForm } from "react-hook-form";
import RecoverPasswordSuccess from "pages/Auth/components/RecoverPasswordSuccess";

function RecoverPasswordNewPassword() {
   const [password, setPassword] = useState("");
   const [passwordConfirm, setPasswordConfirm] = useState("");
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const [finalizeRecoverPassword, setFinalizeRecoverPassword] = useState(false);
   const handleRecoverPasswordNewPassword = () => {
      if (password === passwordConfirm) {
         setFinalizeRecoverPassword(true);
      }
   };

   return (
      <>
         <Header />
         <div className="section_padding bg-recoverPassword">
            {finalizeRecoverPassword === false ? (
               <div className="container recoverpassword-container">
                  <div className="row">
                     <div className="fr_title fr_title2 mb-0 mx-auto">
                        <img
                           className="recoverPassword_web"
                           src={require("assets/images/recoverPassword/recover_password_title_text_web-min-test.webp")}
                           alt=""
                        />
                        <img
                           className="recoverPassword_mobile"
                           src={require("assets/images/recoverPassword/recover_password_title_text_mobile-min-test.webp")}
                           alt=""
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="password"
                              {...register("newPassword", {
                                 required: "Please enter new password",
                              })}
                              className={`${errors.newPassword ? "is-invalid" : "is-valid"}`}
                              placeholder="new password"
                              onChange={e => setPassword(e.target.value)}
                           />
                           <p className="error-message"> {errors.newPassword && errors.newPassword.message}</p>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="login_single single_fra_input">
                           <input
                              type="password"
                              {...register("newPasswordConfirm", {
                                 required: "Please enter new password",
                                 validate: value => {
                                    if (watch("newPassword") !== value) {
                                       return "Your passwords do not match";
                                    }
                                 },
                              })}
                              className={`${errors.newPasswordConfirm ? "is-invalid" : "is-valid"}`}
                              placeholder="confirm password"
                              onChange={e => setPasswordConfirm(e.target.value)}
                           />
                           <p className="error-message"> {errors.newPasswordConfirm && errors.newPasswordConfirm.message}</p>
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="login_single login_btn register_btn register_btn_sub resetPasswordBTN recoverpasswordbtn">
                           <img
                              src={require("assets/images/buttons/BTN_recover_password-min.webp")}
                              alt=""
                              onClick={handleSubmit(handleRecoverPasswordNewPassword)}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            ) : (
               <RecoverPasswordSuccess />
            )}
         </div>
         <div className="divider_img" />

         <Footer />
      </>
   );
}

export default RecoverPasswordNewPassword;
