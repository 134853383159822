import React, { useState, useContext, useEffect } from "react";
import { ScrollContext } from "App";
import Header from "components/Header";
import Footer from "components/Footer";
import ButtonBook from "components/ButtonBook";
import { goToTop } from "helpers/helpers";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import PageMeta from "components/PageMeta";

function Home() {
   const [showVideo, setShowVideo] = useState(false);
   const [showTrailer, setShowTrailer] = useState(false);
   const { scrollToAboutUsRef, scrollToAboutUs, setScrollToAboutUs } = useContext(ScrollContext);

   const openTrailer = () => {
      setShowTrailer(true);
      document.body.style.overflow = "hidden";
   };
   const closeTrailer = () => {
      setShowTrailer(false);
      document.body.style.overflow = "unset";
   };

   useEffect(() => {
      if (scrollToAboutUs) {
         var refHeightPosition = scrollToAboutUsRef.current.getBoundingClientRect().top;
         var headerOffset = 100;
         var offsetPosition = refHeightPosition + window.pageYOffset - headerOffset;
         window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
         });
         setScrollToAboutUs(false);
      }
   }, [scrollToAboutUs]);

   return (
      <>
         <PageMeta
            title={"Play interactive arcade style active games in London ▷ Energize"}
            content={
               "Experience the thrill of interactive arcade style games in London! Energize selection of active games will keep you on your toes and provide an unforgettable experience."
            }
         />
         <Header />
         <div className="banner_area_desing home-video-container bg-1 boxshadow1">
            <div className="col-xl-12 home-title">
               <div className="banner_all_content getEnergized_title">
                  <img src={require("assets/images/home/getEnergized_title_text-min-test.webp")} alt="" />
               </div>
               <ButtonBook doesNavigate={true} navigateTo={"/booking"} title={"BOOK NOW"} customStyle={"book-now-home"} />
               {/*<div className="play-video">*/}
               {/*   <div onClick={openTrailer} className="play-video-container">*/}
               {/*      <img src={require("assets/images/home-video/play-button-razvan2.webp")} />*/}
               {/*      <p className="play-trailer-text">Play trailer</p>*/}
               {/*   </div>*/}
               {/*</div>*/}
            </div>
            {/*<video playsInline autoPlay muted loop className="home-video">*/}
            {/*   <source src={require("assets/images/home-video/energize-teaser-NOSOUND.mp4")} type="video/mp4" />*/}
            {/*</video>*/}
         </div>
         {showTrailer && (
            <>
               {ReactDOM.createPortal(
                  <>
                     <div className="trailer-modal-video-background" onClick={closeTrailer} />
                     <video autoPlay controls className="trailer-modal-video">
                        <source src={require("assets/images/home-video/energize-teaser.mp4")} type="video/mp4" />
                     </video>
                  </>,
                  document.getElementById("modalTrailer"),
               )}
            </>
         )}

         <div className="about_area_design bg-2" ref={scrollToAboutUsRef}>
            <div className="shap shap1">
               <img src={require("assets/images/home/s1.webp")} alt="" />
            </div>
            <div className="shap shap2">
               <img src={require("assets/images/home/s2v2-min.webp")} alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div id="aboutUs" className="heading heading-aboutUs">
                        <h2 id="about">About Energize</h2>
                        <p>
                           At Energize you will play interactive arcade style games where you need to jump, duck, climb, crawl, dodge, think and
                           strategize to advance through dozens of different games, gain points and compete with players from all around the world.
                        </p>
                        <p className="p-about">
                           You play as a team of 2-5 players and move through 11 different rooms choosing the games that you want to play and the
                           level of difficulty. You can play all the rooms in either Collaborative or Versus mode, depending on whether you want to work together or go head-to-head.
                        </p>
                        <p className="p-about">
                           The games are designed to challenge you in various ways and test both your physical as well as your problem solving and
                           dexterity skills. Dodge laser beams, climb lit-up walls, watch your step on a huge glowing floor and more, only at
                           Energize.
                        </p>
                        <p className="p-about">Game on!</p>
                     </div>

                     <div className="all_item_area">
                        <div className="single_item">
                           <div className="s_img">
                              <img src={require("assets/images/home/1-min.webp")} alt="" />
                           </div>
                           <p>Make sure to wear clean, dry, closed toe footwear. Gym or running shoes recommended</p>
                        </div>
                        <div className="single_item">
                           <div className="s_img">
                              <img src={require("assets/images/home/4-min.webp")} alt="" />
                           </div>
                           <p>We recommend wearing comfortable clothing in which you can move freely. Sportswear is best but not compulsory</p>
                        </div>
                        <div className="single_item">
                           <div className="s_img">
                              <img src={require("assets/images/home/3-min.webp")} alt="" />
                           </div>
                           <p>We recommend that you book your slot in advance, especially on weekends</p>
                        </div>
                        <div className="single_item">
                           <div className="s_img">
                              <img src={require("assets/images/home/2-min.webp")} alt="" />
                           </div>
                           <p>Our games can be played in groups of 3-5 players (2 players are accepted but must book for 3)</p>
                        </div>
                     </div>

                     <div className="video_area video-bg cursor_p" onClick={() => setShowVideo(true)}>
                        {/* {showVideo && <iframe className="iframe_home" src="https://www.youtube.com/embed/Uf-1FQacGX0?autoplay=1" />} */}
                        {/*{showVideo && (*/}
                        {/*   <video autoPlay controls className="iframe_home2">*/}
                        {/*      <source src={require("assets/images/home-video/energize-teaser.mp4")} type="video/mp4" />*/}
                        {/*   </video>*/}
                        {/*)}*/}
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="booking_area_design bg-3">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="banner_all_content banner_all_content2">
                        <h1>Book your experience</h1>
                        <ButtonBook doesNavigate={true} navigateTo={"/booking"} title={"BOOK NOW"} customStyle={"book-now-home"} />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="games_area_design">
            <div className="all_game">
               <div className="single_game">
                  <img src={require("assets/images/home/See_Games_v4.webp")} alt="" />
                  <div className="booked">
                     <ButtonBook doesNavigate={true} navigateTo={"/rooms"} title={"SEE GAMES"} customStyle={"book-now-home"} />
                  </div>
                  <div className="shap-img">
                     <img src={require("assets/images/home/See_Games_Neon_v2.webp")} alt="" />
                  </div>
                  <div className="shapping_22">
                     <img src={require("assets/images/home/shap-2-min-test.webp")} alt="" />
                  </div>
               </div>
               <div className="single_game">
                  <img src={require("assets/images/home/See_Scores_v2.webp")} alt="" />
                  <div className="booked">
                     <ButtonBook doesNavigate={true} navigateTo={"/checkscores"} title={"SEE SCORES"} customStyle={"book-now-home"} />
                  </div>
                  <div className="as_img">
                     <img src={require("assets/images/home/as-min.webp")} alt="" />
                  </div>

                  <div className="all_dot">
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                     <div className="d_item"></div>
                  </div>
               </div>
            </div>
            <div className="cir">
               <img src={require("assets/images/home/h.webp")} alt="" />
            </div>
         </div>
         <div className="divider_img" />
         <Footer />
      </>
   );
}
export default Home;
